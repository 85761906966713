const dateCountForPeriod = {
  day: 30,
  month: 365,
  year: 365 * 5,
};

export const dateTimeNow = () => {
  const today = new Date();
  const date = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
    today.getDate()
  ).padStart(2, '0')}`;
  const time = `${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(
    2,
    '0'
  )}`;
  return `${date}T${time}`;
};

export const dateTimeNowPlusTwoDays = () => {
  const date = new Date();
  date.setDate(date.getDate() + 2);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate()
  ).padStart(2, '0')}`;
};

export const takePartOfDate = (date, period) => {
  if (period === 'month') {
    return date.slice(0, 7);
  }
  if (period === 'year') {
    return date.slice(0, 4);
  }
  if (period === 'day') {
    return date.slice(5, 10);
  }
  return date;
};

export const calculateIfDateIntervalTooLarge = (dateTo, dateFrom, period) => {
  const dateCompareTo = Date.parse(dateTo);
  const dateCompareFrom = Date.parse(dateFrom);
  const dateDifference = Math.floor(
    ((dateCompareTo || Date.now()) - (dateCompareFrom || Date.UTC(1970, 1, 1))) /
      (1000 * 60 * 60 * 24)
  );
  const message = 'The progress chart currently only shows the data of the last';
  const adviseMessage = 'You could either select a smaller date interval or change the period.';
  let warning = '';
  if (period === 'day' && dateDifference > dateCountForPeriod.day) {
    warning = `${message} 30 days. ${adviseMessage}`;
  } else if (period === 'month' && dateDifference > dateCountForPeriod.month) {
    warning = `${message} year. ${adviseMessage}`;
  } else if (period === 'year' && dateDifference > dateCountForPeriod.year) {
    warning = `${message} 5 years. ${adviseMessage}`;
  }
  return warning;
};

export const stringDateLocalToUTC = dateString => {
  if (dateString) {
    const d = new Date(dateString);
    return d.toISOString();
  }

  return dateString;
};

export const stringDateUTCToLocal = dateString => {
  if (dateString) {
    const d = new Date(dateString);
    const offsetMs = d.getTimezoneOffset() * 60 * 1000;
    const msLocal = d.getTime() - offsetMs;
    const dateLocal = new Date(msLocal);

    return dateLocal.toLocaleString('sv-SE');
  }

  return dateString;
};
