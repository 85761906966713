import React from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import * as yup from 'yup';
import DialogFooter from '../../components/dialogs/DialogFooter';
import DialogHeader from '../../components/dialogs/DialogHeader';
import {useProjectStyles} from './useStyles';

const EditProject = ({
  open,
  onClose,
  onSubmit,
  squadList,
  project: {id, notes, squadId, title},
}) => {
  const classes = useProjectStyles();

  const validationSchema = yup.object({
    title: yup.string().required('Project title is required'),
    squadId: yup.number().required('Squad name is required'),
    notes: yup.string().notRequired(),
  });

  const initialValues = {
    id,
    title,
    squadId: parseInt(squadId, 10),
    notes,
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogHeader title="Edit project" onClose={onClose} />
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({values, errors, handleChange, isSubmitting}) => (
          <Form>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.paper}>
                    <TextField
                      id="title"
                      name="title"
                      label="Project title*"
                      value={values.title}
                      onChange={handleChange}
                      error={!!errors.title}
                      helperText={errors.title}
                      fullWidth
                    />
                  </div>
                  <div className={classes.paper}>
                    <FormControl className={classes.formControl} error={!!errors.squadId} fullWidth>
                      <InputLabel htmlFor="squadId">Squad*</InputLabel>
                      <Select
                        labelId="squadId"
                        id="squadId"
                        name="squadId"
                        value={values.squadId}
                        onChange={handleChange}
                        error={!!errors.squadId}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {squadList &&
                          squadList.map(squad => (
                            <MenuItem key={squad.id} value={squad.id}>
                              {squad.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{color: 'red'}}>{errors.squadId}</FormHelperText>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="notes"
                    name="notes"
                    label="Project notes"
                    value={values.notes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={5}
                    variant="outlined"
                    error={!!errors.notes}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogFooter onClose={onClose} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditProject.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  squadList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  project: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.number,
    squadId: PropTypes.number,
    notes: PropTypes.string,
  }),
};
EditProject.defaultProps = {
  squadList: [],
  project: {
    title: '',
    id: null,
    squadId: null,
    notes: '',
  },
};

export default EditProject;
