import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Metrics from './pages/Metrics/Metrics';
import Quadrant from './pages/Quadrant/Quadrant';
import Progress from './pages/Progress/Progress';
import ProjectList from './pages/ProjectList';
import Project from './pages/Project';
import Settings from './pages/Settings';
import SquadList from './pages/SquadList';
import Help from './pages/Help';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/metrics" component={Metrics} />
      <Route exact path="/quadrant" component={Quadrant} />
      <Route exact path="/progress" component={Progress} />
      <Route exact path="/projects" component={ProjectList} />
      <Route exact path="/projects/:id" component={Project} />
      <Route exact path="/squads" component={SquadList} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/help" component={Help} />
      <Route exact path="/">
        <Redirect to="/metrics" />
      </Route>
      <Route path="/">
        <p> Whoops! Something went wrong! :(</p>
      </Route>
    </Switch>
  );
};

export default Routes;
