import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ProgressChart from '../../components/ProgressChart';
import CustomFilter from '../../components/CustomFilter';

const Progress = () => {
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateTo: '',
    squadIds: [],
    projectIds: [],
  });
  const [hasLoaded, setHasLoaded] = useState(false);

  const handleLoad = didLoad => {
    setHasLoaded(didLoad);
  };

  const handleFilterClear = resetForm => {
    setFilters({
      dateTo: '',
      dateFrom: '',
      squadIds: [],
      projectIds: [],
    });
    resetForm({});
  };

  const handleFilterSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    setFilters({
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
      squadIds: values.squadIds,
      projectIds: values.projectIds,
    });
    setSubmitting(false);
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h5" gutterBottom>
          Filtering options
        </Typography>
        <CustomFilter
          onClear={handleFilterClear}
          onSubmit={handleFilterSubmit}
          title="Select Date/Time"
          shouldDisplayProjectFilter
          shouldDisplaySquadFilter
          filters={filters}
          hasLoaded={hasLoaded}
        />
      </Box>
      <Grid>
        <ProgressChart filters={filters} hasLoaded={hasLoaded} onLoad={handleLoad} />
      </Grid>
    </>
  );
};

export default Progress;
