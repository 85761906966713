import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import PropTypes from 'prop-types';
import useDialogHeaderStyles from './useDialogHeaderStyles';

const DialogHeader = ({title, onClose}) => {
  const classes = useDialogHeaderStyles();
  return (
    <DialogTitle disableTypography className={classes.dialogTitle}>
      <h2>{title}</h2>
      <IconButton className={classes.dialogExitButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

DialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialogHeader;
