import React from 'react';
import PropTypes from 'prop-types';
import {TableCell, TableRow} from '@material-ui/core';
import {useRowBodyStyles} from './useStyles';

const RowBody = ({rowsPerCurrentPage, i, row}) => {
  const rowBodyStyles = useRowBodyStyles();
  const showBorder = rowsPerCurrentPage === i + 1 ? rowBodyStyles.border : '';
  return (
    <TableRow key={row.projectTitle + row.squadName}>
      <TableCell classes={{root: showBorder}} align="left">
        {row.projectTitle}
      </TableCell>
      <TableCell classes={{root: showBorder}} align="left">
        {row.squadName}
      </TableCell>
      <TableCell classes={{root: showBorder}} align="center">
        {row.deploymentFrequency.toFixed(2)}
      </TableCell>
      <TableCell classes={{root: showBorder}} align="center">
        {row.leadTime}
      </TableCell>
      <TableCell classes={{root: showBorder}} align="center">
        {row.deploymentDuration}
      </TableCell>
      <TableCell classes={{root: showBorder}} align="center">
        {row.failureRate.toFixed(2)}
      </TableCell>
    </TableRow>
  );
};

RowBody.propTypes = {
  row: PropTypes.shape({
    squadName: PropTypes.string,
    projectTitle: PropTypes.string,
    deploymentFrequency: PropTypes.number,
    leadTime: PropTypes.number,
    deploymentDuration: PropTypes.number,
    failureRate: PropTypes.number,
  }).isRequired,
  i: PropTypes.number.isRequired,
  rowsPerCurrentPage: PropTypes.number.isRequired,
};

export default RowBody;
