import {createStore, compose} from 'redux';
import {loadUser} from 'redux-oidc';
import reducers from './reducers';
import userManager from '../utils/userManager';

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(reducers, composeEnhancers());

loadUser(store, userManager);

export default store;
