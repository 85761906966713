import React from 'react';
import {TableHead, TableRow, TableCell} from '@material-ui/core';
import {useHeaderStyles} from './useStyles';

const TableHeader = () => {
  const headerClasses = useHeaderStyles();
  return (
    <TableHead>
      <TableRow classes={{root: headerClasses.greyBackground}}>
        <TableCell classes={{root: headerClasses.metricsFontSize}} align="left">
          Metrics
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <TableRow classes={{root: headerClasses.purpleBackground}}>
        <TableCell align="left" classes={{root: headerClasses.bold}}>
          Project
        </TableCell>
        <TableCell align="left" classes={{root: headerClasses.bold}}>
          Squad
        </TableCell>
        <TableCell align="center" classes={{root: headerClasses.bold}}>
          Deployment frequency (#/day)
        </TableCell>
        <TableCell align="center" classes={{root: headerClasses.bold}}>
          Lead time (min)
        </TableCell>
        <TableCell align="center" classes={{root: headerClasses.bold}}>
          Deployment duration (min)
        </TableCell>
        <TableCell align="center" classes={{root: headerClasses.bold}}>
          Failure rate (%)
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
