import {Tooltip} from '@devexpress/dx-react-chart-material-ui';
import React from 'react';
import useTooltipSheetStyles from './useTooltipSheetStyles';

/* eslint-disable react/jsx-props-no-spreading */
const TooltipSheet = props => {
  const classes = useTooltipSheetStyles();
  return <Tooltip.Sheet {...props} className={classes.sheet} />;
};

export default TooltipSheet;
