import {Tooltip} from '@devexpress/dx-react-chart-material-ui';
import React, {forwardRef} from 'react';
import useTooltipArrowStyles from './useTooltipArrowStyles';

/* eslint-disable react/jsx-props-no-spreading */
const TooltipArrow = forwardRef((props, ref) => {
  const classes = useTooltipArrowStyles();
  return <Tooltip.Arrow ref={ref} {...props} className={classes.arrow} />;
});

export default TooltipArrow;
