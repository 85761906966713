import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import {useLocation} from 'react-router';
import nameMap from '../../nameMap';
import {useCrumbsStyles} from './useStyles';

const LinkRouter = ({color, to, children}) => {
  return (
    <Link color={color} to={to} component={RouterLink}>
      {children}
    </Link>
  );
};

LinkRouter.propTypes = {
  color: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.string.isRequired,
};

LinkRouter.defaultProps = {
  color: 'inherit',
  to: '/',
};

const Crumbs = () => {
  const classes = useCrumbsStyles();
  const location = useLocation();
  const [pathNames, setPathNames] = useState([]);

  useEffect(() => {
    const curPathNames = location.pathname.split('/').filter(x => x);
    setPathNames(curPathNames);
  }, [location]);

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
      <LinkRouter color="inherit" to="/">
        {nameMap['/']}
      </LinkRouter>
      {pathNames.map((value, index) => {
        const isLast = index === pathNames.length - 1;
        const link = `/${pathNames.slice(0, index + 1).join('/')}`;
        const to = `/${value}`;
        let printValue = nameMap[to];
        if (nameMap[to] === undefined) {
          printValue = value;
        }
        return isLast ? (
          <Typography className={classes.typography} key={to}>
            {printValue}
          </Typography>
        ) : (
          <LinkRouter color="inherit" to={link} key={to}>
            {printValue}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};
export default Crumbs;
