import React from 'react';
import PropTypes from 'prop-types';
import {TableFooter} from '@material-ui/core';
import RowFooter from './RowFooter';

const CustomTableFooter = ({stats}) => {
  return (
    <TableFooter>
      {stats.map(row => (
        <RowFooter row={row} key={row.name} />
      ))}
    </TableFooter>
  );
};

CustomTableFooter.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      deploymentFrequency: PropTypes.number,
      leadTime: PropTypes.number,
      deploymentDuration: PropTypes.number,
      failureRate: PropTypes.number,
      map: PropTypes.func,
    })
  ).isRequired,
};

export default CustomTableFooter;
