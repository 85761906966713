import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable, {MTableToolbar} from 'material-table';
import tableIcons from './tableIcons';

const Table = ({
  title,
  columns,
  data,
  tableRef,
  openEditModal,
  handleData,
  showEditAction,
  openDeleteModal,
  showDeleteAction,
  actions,
  openFilter,
  showFilterAction,
  onLoad,
}) => {
  return (
    <>
      <MaterialTable
        title={title}
        columns={columns.map(column => {
          return {
            ...column,
            sorting: false,
          };
        })}
        data={data}
        icons={tableIcons}
        options={{
          pageSize: 20,
          pageSizeOptions: [],
          emptyRowsWhenPaging: false,
          search: false,
          headerStyle: {
            backgroundColor: '#f6f6f6',
            fontWeight: 'bold',
          },
          actionsColumnIndex: -1,
        }}
        actions={[
          ...actions,
          showFilterAction
            ? {
                icon: tableIcons.Filter,
                tooltip: 'Filter Data',
                isFreeAction: true,
                onClick: () => openFilter(),
              }
            : null,
          {
            icon: tableIcons.Refresh,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => {
              tableRef.current.onQueryChange();
              onLoad(false);
            },
          },
          dataToEdit =>
            showEditAction
              ? {
                  icon: tableIcons.Edit,
                  tooltip: 'Edit',
                  onClick: () => {
                    handleData(dataToEdit);
                    openEditModal();
                  },
                }
              : null,
          dataToDelete =>
            showDeleteAction
              ? {
                  icon: tableIcons.Delete,
                  tooltip: 'Delete',
                  onClick: () => {
                    handleData(dataToDelete);
                    openDeleteModal();
                  },
                }
              : null,
        ]}
        components={{
          /* eslint-disable react/jsx-props-no-spreading */
          Toolbar: toolbarProps => (
            <div style={{backgroundColor: '#cccccc'}}>
              <MTableToolbar {...toolbarProps} />
            </div>
          ),
          /* eslint-enable react/jsx-props-no-spreading */
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
        tableRef={tableRef}
      />
    </>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.func.isRequired,
  tableRef: PropTypes.shape({
    current: PropTypes.shape({
      onQueryChange: PropTypes.func,
    }),
  }).isRequired,
  openEditModal: PropTypes.func,
  handleData: PropTypes.func,
  showEditAction: PropTypes.bool,
  openDeleteModal: PropTypes.func,
  showDeleteAction: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.func),
  openFilter: PropTypes.func,
  showFilterAction: PropTypes.bool,
  onLoad: PropTypes.func,
};

Table.defaultProps = {
  title: '',
  openEditModal: () => {},
  showEditAction: false,
  handleData: () => {},
  openDeleteModal: () => {},
  showDeleteAction: false,
  actions: [],
  openFilter: () => {},
  showFilterAction: false,
  onLoad: () => {},
};

export default Table;
