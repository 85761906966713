import {makeStyles} from '@material-ui/core/styles';

const useHelpStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 700,
    padding: 36,
  },
  margin: {
    marginBottom: 32,
  },
  smallMargin: {
    marginBottom: 18,
  },
  indent: {
    textIndent: '0.75em',
  },
  identWithMargin: {
    marginBottom: 18,
    textIndent: '0.75em',
  },
});

export default useHelpStyles;
