const mapProjectsToOptions = projectList => {
  return projectList.map(project => {
    return {
      label: project.title,
      value: project.id,
    };
  });
};

export default mapProjectsToOptions;
