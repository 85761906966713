import {makeStyles} from '@material-ui/core/styles';

export const useDeploymentStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

export const useProjectDetailsStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    paddingRight: 30,
  },
  notes: {
    fontSize: 'medium',
  },
  title: {
    fontSize: 'x-large',
    paddingBottom: 10,
  },
  notesBox: {
    outlineStyle: 'solid',
    outlineWidth: '3px',
    outlineColor: '#d4c3f9',
    minHeight: '75%',
    padding: 5,
  },
}));

export const useProjectGridRowStyles = makeStyles(() => ({
  heading: {
    fontWeight: 700,
    fontSize: 'large',
  },
  content: {
    fontSize: 'large',
  },
  root: {
    paddingTop: 10,
  },
}));

export const useProjectStyles = makeStyles(theme => ({
  title: {
    fontSize: 'x-large',
    paddingBottom: 10,
    paddingRight: 20,
  },
  space: {
    marginTop: '2em',
  },
  paper: {
    padding: theme.spacing(2.5),
  },
  formControl: {
    minWidth: 195,
  },
}));

export const useDialogFooterStyles = makeStyles(theme => ({
  dialogActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(3),
  },
}));
