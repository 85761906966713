import makeStyles from '@material-ui/core/styles/makeStyles';

export const useNavigatorStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 65,
    top: 100,
    bottom: 160,
    position: 'fixed',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
  },
  menuIcon: {
    color: '#9b9b9b',
  },
  menuItem: {
    margin: 'auto',
    minWidth: '0px',
  },
  menuButton: {
    textAlign: 'center',
    padding: '15px 0',
  },
}));

export const useUserSectionStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 65,
    height: 100,
    bottom: 0,
    position: 'fixed',
    paddingTop: 20,
    alignItems: 'center',
  },
  logout: {
    width: 30,
    height: 30,
    paddingTop: '10px',
    color: 'white',
  },
  userIcon: {
    width: 40,
    height: 40,
    color: 'white',
  },
}));

export const useSidebarStyles = makeStyles(() => ({
  typography: {
    fontSize: '0.9rem',
    textAlign: 'center',
    fontWeight: 'bold',
    width: 65,
    color: 'white',
  },
  logo: {
    width: 54,
    margin: '5px',
    backgroundColor: 'rgba(255, 52, 84, 1)',
  },
  navigationSideBar: {
    position: 'fixed',
  },
}));
