import React from 'react';
import {useHistory} from 'react-router';
import {ExitToApp, AccountCircle} from '@material-ui/icons';
import {IconButton, Tooltip, Grid} from '@material-ui/core';
import {useUserSectionStyles} from './useStyles';
import setToken from '../../state/actions/setToken';
import store from '../../state/store';
import userManager from '../../utils/userManager';

const UserSection = () => {
  const classes = useUserSectionStyles();
  const history = useHistory();
  const email = sessionStorage.getItem('upn') || '';
  const fullName = email.replace('@devbridge.com', '').replace('.', ' ');

  const logout = () => {
    sessionStorage.clear();
    userManager.removeUser();
    store.dispatch(setToken(null));
    history.push('/login');
  };

  return (
    <div className={classes.root}>
      <Tooltip title={fullName} placement="left">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <AccountCircle className={classes.userIcon} />
          </Grid>
        </Grid>
      </Tooltip>
      <Tooltip title="Logout" placement="left">
        <IconButton onClick={logout} className={classes.logout}>
          <ExitToApp className={classes.logout} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default UserSection;
