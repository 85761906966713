import isEmpty from 'lodash.isempty';

const getRequestUrl = (url, params) => {
  const searchParams = new URLSearchParams();
  new URLSearchParams(params).forEach((value, key) => {
    if (!isEmpty(value)) {
      searchParams.append(key, value);
    }
  });

  if (searchParams.toString().length > 0) {
    if (!url.includes('?')) {
      return `${url}?${searchParams.toString()}`;
    }
    return `${url}&${searchParams.toString()}`;
  }
  return `${url}${searchParams.toString()}`;
};

export default getRequestUrl;
