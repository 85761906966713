import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import useFetch from '../../useFetch';
import QuadrantChart from './QuadrantChart';
import CustomFilter from '../../components/CustomFilter';
import getRequestUrl from '../../utils/getRequestUrl';

const Quadrant = () => {
  const X_AXIS_ADJUSTMENT = 1.2;
  const [quadrantData, setQuadrantData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [maxDeploymentAxis, setMaxDeploymentAxis] = useState(0);
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateTo: '',
    squadIds: [],
    projectIds: [],
  });
  const [hasLoaded, setHasLoaded] = useState(false);
  const getQuadrantData = async () => {
    let url = `/global/quadrant`;
    url = getRequestUrl(url, filters);
    await useFetch(url, 'GET')
      .then(data => data.json())
      .then(data => {
        setQuadrantData(data);
        setHasLoaded(true);
      });
  };

  const removeNullAttributesFromObject = obj => {
    const filteredObject = obj;
    Object.entries(filteredObject).forEach(
      ([key, value]) =>
        (value && typeof value === 'object' && removeNullAttributesFromObject(value)) ||
        (value === null && delete filteredObject[key])
    );
    return filteredObject;
  };

  useEffect(() => {
    if (!quadrantData) {
      setHasLoaded(false);
    }
    getQuadrantData();
  }, [filters]);

  useEffect(() => {
    const data = [];
    if (quadrantData) {
      const deployFreqSettings = Math.max(
        ...quadrantData.map(element => element.deployFreqArgumentField)
      );
      const deployFreqMax = Math.max(...quadrantData.map(element => element.deploymentFrequency));
      setMaxDeploymentAxis(Math.max(deployFreqSettings, deployFreqMax) * X_AXIS_ADJUSTMENT);
      quadrantData.forEach(quadrantObject => {
        const newObject = removeNullAttributesFromObject(quadrantObject);
        data.push(newObject);
      });
    }
    setFilteredData(data);
  }, [quadrantData]);

  const handleFilterClear = resetForm => {
    setFilters({
      dateTo: '',
      dateFrom: '',
      squadIds: [],
      projectIds: [],
    });
    resetForm({});
  };

  const handleFilterSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    setFilters({
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
      squadIds: values.squadIds,
      projectIds: values.projectIds,
    });
    setSubmitting(false);
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h5" gutterBottom>
          Filtering options
        </Typography>
        <CustomFilter
          onClear={handleFilterClear}
          onSubmit={handleFilterSubmit}
          title="Select Date/Time"
          shouldDisplayProjectFilter
          shouldDisplaySquadFilter
          hasLoaded={hasLoaded}
        />
      </Box>
      <Grid>
        <QuadrantChart
          maxDeploymentAxis={maxDeploymentAxis}
          quadrantData={filteredData}
          hasLoaded={hasLoaded}
        />
      </Grid>
    </>
  );
};

export default Quadrant;
