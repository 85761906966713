import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@material-ui/core';
import MetricsTable from './MetricsTable';
import CustomFilter from '../../components/CustomFilter';
import useFetch from '../../useFetch';
import getRequestUrl from '../../utils/getRequestUrl';

const Metrics = () => {
  const [metricsData, setMetricsData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [squadList, setSquadList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateTo: '',
    squadIds: [],
    projectIds: [],
  });
  const [hasLoaded, setHasLoaded] = useState(false);

  const getMetricsData = async () => {
    let url = `/global/metrics`;
    url = getRequestUrl(url, filters);

    await useFetch(`${url}`, 'GET')
      .then(response => response.json())
      .then(data => {
        setMetricsData(data);
        setHasLoaded(true);
      });
  };

  const getSquadList = async () => {
    const url = '/squads';
    await useFetch(url, 'GET')
      .then(data => data.json())
      .then(data => {
        setSquadList(data);
      });
  };

  const getProjectList = async () => {
    const url = '/projects';
    await useFetch(url, 'GET')
      .then(data => data.json())
      .then(data => {
        setProjectList(data);
      });
  };

  const handleRefreshData = () => {
    setMetricsData({});
    setHasLoaded(false);
    getSquadList();
    getProjectList();
    getMetricsData();
  };

  useEffect(() => {
    handleRefreshData();
  }, [filters]);

  const handleFilterClear = resetForm => {
    setFilters({
      dateTo: '',
      dateFrom: '',
      squadIds: [],
      projectIds: [],
    });
    resetForm({});
  };

  const handleFilterSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    setFilters({
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
      squadIds: values.squadIds,
      projectIds: values.projectIds,
    });
    setSubmitting(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h5" gutterBottom>
          Filtering options
        </Typography>
        <CustomFilter
          onClear={handleFilterClear}
          onSubmit={handleFilterSubmit}
          projectList={projectList}
          squadList={squadList}
          title="Last deployment interval:"
          hasLoaded={hasLoaded}
        />
      </Box>
      <MetricsTable
        data={metricsData}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        hasLoaded={hasLoaded}
      />
    </>
  );
};

export default Metrics;
