import {Box, Button, Grid, TextField, Typography, Tooltip} from '@material-ui/core';
import {Clear, FilterList, Filter1, Filter3} from '@material-ui/icons';
import {Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';
import isEmpty from 'lodash.isempty';
import useData from '../../useData';
import mapProjectsToOptions from '../../utils/mapProjectsToOptions';
import mapSquadsToOptions from '../../utils/mapSquadsToOptions';
import CustomMultiSelect from '../fields/CustomMultiSelect';
import useFilterStyles from './useFilterStyles';

const getDateFromated = date => {
  const month = date.getMonth() > 9 ? date.getMonth() : `0${date.getMonth()}`;
  return `${date.getFullYear()}-${month}-${date.getDate()}T00:00`;
};

const selectLastMonth = (data, setFieldValue) => {
  const date = new Date();
  setFieldValue('dateFrom', getDateFromated(date));
};

const selectLastThreeMonth = (data, setFieldValue) => {
  const date = new Date();
  date.setMonth(date.getMonth() - 2);
  setFieldValue('dateFrom', getDateFromated(date));
};

const CustomFilter = ({
  onClear,
  onSubmit,
  shouldDisplayProjectFilter,
  shouldDisplaySquadFilter,
  title,
  hasLoaded,
  squadList,
  projectList,
}) => {
  const classes = useFilterStyles({disabled: hasLoaded});

  let projectOptions = [];
  let squadOptions = [];

  if (!isEmpty(projectList)) {
    projectOptions = mapProjectsToOptions(projectList);
  } else if (shouldDisplayProjectFilter) {
    projectOptions = mapProjectsToOptions(useData('/projects'));
  }

  if (!isEmpty(squadList)) {
    squadOptions = mapSquadsToOptions(squadList);
  } else if (shouldDisplaySquadFilter) {
    squadOptions = mapSquadsToOptions(useData('/squads'));
  }

  const validationSchema = yup.object({
    dateFrom: yup.date().min('1900-01-01').max('2200-01-01').nullable(),
    dateTo: yup
      .date()
      .min('1900-01-01')
      .max('2200-01-01')
      .when(
        'dateFrom',
        (dateFrom, schema) =>
          dateFrom &&
          schema.min(dateFrom, 'This date must be later than the one you selected for date from')
      )
      .nullable(),
    projectIds: yup.array().of(yup.number()),
    squadIds: yup.array().of(yup.number()),
  });

  const initialValues = {
    dateFrom: '',
    dateTo: '',
    projectIds: [],
    squadIds: [],
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({values, errors, handleChange, isSubmitting, resetForm, setFieldValue}) => (
          <Form className={classes.loading}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  name="dateFrom"
                  label="Date from:"
                  type="datetime-local"
                  size="small"
                  className={classes.dateField}
                  value={values.dateFrom}
                  onChange={handleChange}
                  error={!!errors.dateFrom}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.dateFrom}
                  disabled={!hasLoaded}
                />
                <Grid container>
                  <Tooltip title="Select range - last month" placement="bottom">
                    <Box mt={3}>
                      <Button
                        size="small"
                        disabled={isSubmitting || !hasLoaded}
                        onClick={data => selectLastMonth(data, setFieldValue)}
                        type="submit"
                        variant="contained"
                        startIcon={<Filter1 />}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Select range - last 3 months" placement="bottom">
                    <Box mt={3} ml={5}>
                      <Button
                        size="small"
                        disabled={isSubmitting || !hasLoaded}
                        onClick={data => selectLastThreeMonth(data, setFieldValue)}
                        type="submit"
                        variant="contained"
                        startIcon={<Filter3 />}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  name="dateTo"
                  label="Date to:"
                  type="datetime-local"
                  size="small"
                  className={classes.dateField}
                  value={values.dateTo}
                  onChange={handleChange}
                  error={!!errors.dateTo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.dateTo}
                  disabled={!hasLoaded}
                />
              </Grid>
              {!isEmpty(projectOptions) && (
                <Grid item xs={12} md={6} lg={2}>
                  <CustomMultiSelect
                    value={values.projectIds}
                    options={projectOptions}
                    fieldName="projectIds"
                    handleChange={handleChange}
                    label="Select project:"
                    disabled={!hasLoaded}
                  />
                </Grid>
              )}
              {!isEmpty(squadOptions) && (
                <Grid item xs={12} md={6} lg={3}>
                  <CustomMultiSelect
                    value={values.squadIds}
                    options={squadOptions}
                    fieldName="squadIds"
                    handleChange={handleChange}
                    label="Select squad:"
                    disabled={!hasLoaded}
                  />
                </Grid>
              )}
              <Grid item xs={6} md={2} lg={1}>
                <Box mt={3}>
                  <Button
                    size="small"
                    disabled={isSubmitting || !hasLoaded}
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<FilterList />}
                  >
                    {isSubmitting ? 'Filtering..' : 'Filter'}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <Box mt={3}>
                  <Button
                    size="small"
                    onClick={() => onClear(resetForm)}
                    variant="contained"
                    startIcon={<Clear />}
                    disabled={!hasLoaded}
                  >
                    Clear
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomFilter;

CustomFilter.propTypes = {
  onClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  shouldDisplayProjectFilter: PropTypes.bool,
  shouldDisplaySquadFilter: PropTypes.bool,
  title: PropTypes.string,
  hasLoaded: PropTypes.bool,
  squadList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  projectList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      lastDeployed: PropTypes.string,
      notes: PropTypes.string,
      squadId: PropTypes.number,
      squadName: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

CustomFilter.defaultProps = {
  shouldDisplayProjectFilter: false,
  shouldDisplaySquadFilter: false,
  title: '',
  hasLoaded: false,
  squadList: [],
  projectList: [],
};
