import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import * as yup from 'yup';
import CustomRadio from '../../components/fields/CustomRadio';
import {dateTimeNow, dateTimeNowPlusTwoDays} from '../../utils/dateUtils';
import DialogHeader from '../../components/dialogs/DialogHeader';
import DialogFooter from '../../components/dialogs/DialogFooter';
import {useDeploymentStyles} from './useStyles';

const CreateDeployment = ({open, onClose, onSubmit}) => {
  const classes = useDeploymentStyles();

  const validationSchema = yup.object({
    date: yup
      .date()
      .min('1900-01-01')
      .max(dateTimeNowPlusTwoDays())
      .required('Deployment date/time is required'),
    isDefect: yup.string().required('Has defect field is required'),
    notes: yup.string().notRequired(),
    leadTime: yup
      .number()
      .integer('Lead time must be an integer')
      .moreThan(0, 'Lead time must be a positive number')
      .nullable(),
    deploymentDuration: yup
      .number()
      .integer('Deployment duration must be an integer')
      .moreThan(0, 'Deployment duration must be a positive number')
      .nullable(),
  });

  const initialValues = {
    date: dateTimeNow(),
    isDefect: 'No',
    notes: '',
    leadTime: null,
    deploymentDuration: null,
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogHeader onClose={onClose} title="New deployment" />
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({values, errors, handleChange, isSubmitting}) => (
          <Form>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.paper}>
                    <TextField
                      id="date"
                      name="date"
                      label="Deployment date*"
                      type="datetime-local"
                      value={values.date}
                      onChange={handleChange}
                      error={!!errors.date}
                      helperText={errors.date}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.paper}>
                    <FormLabel component="label" error={!!errors.isDefect}>
                      Deployment has a bug?*
                    </FormLabel>
                    <CustomRadio name="isDefect" type="radio" value="Yes" label="Yes" />
                    <CustomRadio name="isDefect" type="radio" value="No" label="No" />
                    <FormHelperText style={{color: 'red'}}>{errors.isDefect}</FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.paper}>
                    <TextField
                      id="leadTime"
                      label="Lead time (min)"
                      name="leadTime"
                      type="number"
                      value={values.leadTime || ''}
                      onChange={handleChange}
                      error={!!errors.leadTime}
                      helperText={errors.leadTime}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.paper}>
                    <TextField
                      id="deploymentDuration"
                      label="Deployment duration (min)"
                      name="deploymentDuration"
                      type="number"
                      value={values.deploymentDuration || ''}
                      onChange={handleChange}
                      error={!!errors.deploymentDuration}
                      helperText={errors.deploymentDuration}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="notes"
                    name="notes"
                    label="Deployment notes"
                    value={values.notes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={10}
                    variant="outlined"
                    error={!!errors.notes}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogFooter isSubmitting={isSubmitting} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

CreateDeployment.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateDeployment;
