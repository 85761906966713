import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import React from 'react';
import * as yup from 'yup';
import DialogHeader from '../../components/dialogs/DialogHeader';
import DialogFooter from '../../components/dialogs/DialogFooter';
import useSquadStyles from './useSquadStyles';

const CreateSquad = ({open, onClose, onSubmit}) => {
  const classes = useSquadStyles();
  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Squad name is required')
      .trim('Squad name cannot be blank')
      .strict()
      .max(255, 'Squad name must have no more than 255 characters'),
  });

  const initialValues = {
    name: '',
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogHeader onClose={onClose} title="Create squad" />
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({values, errors, handleChange, isSubmitting}) => (
          <Form>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.paper}>
                    <TextField
                      id="name"
                      name="name"
                      label="Squad name*"
                      value={values.name}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogFooter isSubmitting={isSubmitting} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateSquad;

CreateSquad.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
