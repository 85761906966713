const {makeStyles} = require('@material-ui/core');

const useTooltipArrowStyles = makeStyles({
  arrow: {
    '&::after': {
      background: '#ebe2ff',
    },
    width: '100%',
  },
});

export default useTooltipArrowStyles;
