import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import React from 'react';
import useMultiSelectStyles from './useMultiSelectStyles';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
};

const CustomMultiSelect = ({value, handleChange, fieldName, options, label, disabled}) => {
  const classes = useMultiSelectStyles();

  const getNameById = id => {
    const optionFound = options.find(option => option.value === id);
    return optionFound.label;
  };

  return (
    <>
      <InputLabel id={`${fieldName}Label`}>{label}</InputLabel>
      <Select
        labelId={`${fieldName}Label`}
        id={fieldName}
        name={fieldName}
        value={value}
        onChange={handleChange}
        className={classes.selectForm}
        input={<Input />}
        renderValue={selected => {
          if (selected.length === 0) {
            return <em>None</em>;
          }
          return selected.map(id => getNameById(id)).join(', ');
        }}
        MenuProps={MenuProps}
        multiple
        displayEmpty
        disabled={disabled}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value} style={{whiteSpace: 'normal'}}>
            <Checkbox checked={value.indexOf(option.value) > -1} color="primary" />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default CustomMultiSelect;

CustomMultiSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
