import {makeStyles} from '@material-ui/core/styles';

const useLoginStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'whitesmoke',
  },
  button: {
    color: theme.palette.getContrastText('#FF3454'),
    backgroundColor: '#FF3454',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  typography: {
    fontSize: '1.4rem',
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

export default useLoginStyles;
