import React from 'react';
import PropTypes from 'prop-types';
import {TableBody} from '@material-ui/core';
import RowBody from './RowBody';

const CustomTableBody = ({rowsPerPage, records, page}) => {
  const currentPageRows = records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <TableBody>
      {currentPageRows.map((row, i) => (
        <RowBody
          i={i}
          rowsPerCurrentPage={currentPageRows.length}
          row={row}
          key={row.projectTitle + row.squadName}
        />
      ))}
    </TableBody>
  );
};

CustomTableBody.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      squadName: PropTypes.string,
      deploymentFrequency: PropTypes.number,
      leadTime: PropTypes.number,
      deploymentDuration: PropTypes.number,
      failureRate: PropTypes.number,
      map: PropTypes.func,
    })
  ).isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default CustomTableBody;
