import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, {createRef, useEffect, useState} from 'react';
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';
import CustomFilter from '../../components/CustomFilter';
import ResponseSnackbar from '../../components/ResponseSnackbar';
import Table from '../../components/Table/Table';
import CreateProject from './CreateProject';
import EditProject from '../Project/EditProject';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import TableIcons from '../../components/Table/tableIcons';
import useFetch from '../../useFetch';
import errorCodes from '../../utils/errorCodes';
import getRequestUrl from '../../utils/getRequestUrl';

const tableRef = createRef();

const ProjectList = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [squadList, setSquadList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [snackbar, setSnackbar] = useState({
    message: '',
    color: '',
  });
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateTo: '',
    squadIds: [],
    projectIds: [],
  });

  const columns = [
    {
      title: 'Name',
      field: 'title',
    },
    {
      title: 'Deployment count',
      field: 'deploymentCount',
    },
    {
      title: 'Squad',
      field: 'squadName',
    },
    {
      title: 'Last deployed',
      field: 'lastDeployed',
    },
  ];

  const getSquadList = async () => {
    if (openCreateModal || openEditModal || showFilter) {
      const url = '/squads';
      await useFetch(url, 'GET')
        .then(data => data.json())
        .then(data => {
          setSquadList(data);
        });
    }
  };

  const getProjectList = async () => {
    if (showFilter) {
      const url = '/projects';
      await useFetch(url, 'GET')
        .then(data => data.json())
        .then(data => {
          setProjectList(data);
        });
    }
  };

  useEffect(() => {
    getSquadList();
  }, [openCreateModal, openEditModal, showFilter]);

  useEffect(() => {
    getProjectList();
  }, [showFilter]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSelectedData = data => {
    setSelectedProject(data);
  };

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCreateSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    useFetch(`/projects`, 'POST', values).then(response => {
      if (response.status >= 200 && response.status < 300) {
        setSubmitting(false);
        setOpenCreateModal(false);
        setSnackbar({
          message: `The project was added successfully.`,
          color: 'success',
        });
        setOpenSnackbar(true);
        tableRef.current.onQueryChange();
      } else {
        response
          .json()
          .then(data => {
            throw Error(JSON.stringify(data));
          })
          .catch(err => {
            const errorMessage = err.message.includes(errorCodes.ALREADY_EXISTS)
              ? `The project could not be added. This project title already exists.`
              : `The project could not be added.`;
            setSnackbar({
              message: errorMessage,
              color: 'error',
            });
            setOpenSnackbar(true);
          });
        setSubmitting(false);
      }
    });
  };

  const handleFilterSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    setFilters({
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
      squadIds: values.squadIds,
      projectIds: values.projectIds,
    });
    tableRef.current.onQueryChange();
    setSubmitting(false);
  };

  const handleFilterClear = resetForm => {
    resetForm({});
    setFilters({
      dateTo: '',
      dateFrom: '',
      squadIds: [],
      projectIds: [],
    });
    tableRef.current.onQueryChange();
  };

  const handleEditSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    useFetch(`/projects/${values.id}`, 'PUT', values).then(response => {
      if (response.status >= 200 && response.status < 300) {
        setSubmitting(false);
        setOpenEditModal(false);
        setSnackbar({
          message: `The project was edited successfully.`,
          color: 'success',
        });
        setOpenSnackbar(true);
        tableRef.current.onQueryChange();
      } else {
        response
          .json()
          .then(data => {
            throw Error(JSON.stringify(data));
          })
          .catch(err => {
            const errorMessage = err.message.includes(errorCodes.ALREADY_EXISTS)
              ? `The project could not be edited. This project title already exists.`
              : `The project could not be edited.`;
            setSnackbar({
              message: errorMessage,
              color: 'error',
            });
            setOpenSnackbar(true);
          });
        setSubmitting(false);
      }
    });
  };

  const handleDeleteSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    useFetch(`/projects/${values}`, 'DELETE')
      .then(() => {
        setSnackbar({
          message: 'The project was deleted successfully.',
          color: 'success',
        });
        setOpenSnackbar(true);
        if (tableRef.current.state.data.length - 1 === 0 && tableRef.current.state.query.page > 0) {
          tableRef.current.state.query.page -= 1;
        }
        tableRef.current.onQueryChange();
      })
      .catch(() => {
        setSnackbar({
          message: 'The project could not be deleted.',
          color: 'error',
        });
        setOpenSnackbar(true);
      });
    setSubmitting(false);
    setOpenDeleteModal(false);
  };

  const [hasLoaded, setHasLoaded] = useState(false);
  const handleLoad = didLoad => {
    setHasLoaded(didLoad);
  };

  const data = async query => {
    let url = `/projects?limit=${query.pageSize}&page=${query.page}`;
    url = getRequestUrl(url, filters);

    const response = await useFetch(url, 'GET');
    const jsonData = await response.json();
    const {headers} = response;
    setHasLoaded(true);
    return {
      data: jsonData,
      page: query.page,
      totalCount: parseInt(headers.get('X-Total-Count') || 0, 10),
    };
  };

  const history = useHistory();
  const actions = [
    rowData => ({
      icon: TableIcons.OpenInBrowser,
      tooltip: 'Open',
      onClick: () => {
        history.push(`projects/${rowData.id}`);
      },
    }),
  ];

  return (
    <>
      <Grid container justify="flex-end">
        <Box mb={5}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenCreateModal}
          >
            Create Project
          </Button>
        </Box>
      </Grid>
      <Box mb={3}>
        <Collapse in={showFilter}>
          <Typography variant="h5" gutterBottom>
            Filtering options
          </Typography>
          <CustomFilter
            onClear={handleFilterClear}
            onSubmit={handleFilterSubmit}
            projectList={projectList}
            squadList={squadList}
            title="Last deployment interval:"
            hasLoaded={hasLoaded}
          />
        </Collapse>
      </Box>
      <CreateProject
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        onSubmit={handleCreateSubmit}
        squadList={squadList}
      />
      <EditProject
        onSubmit={handleEditSubmit}
        onClose={handleCloseEditModal}
        open={openEditModal}
        project={selectedProject}
        squadList={squadList}
      />
      <DeleteDialog
        onSubmit={handleDeleteSubmit}
        onClose={handleCloseDeleteModal}
        id={selectedProject.id}
        entityName={selectedProject.title}
        open={openDeleteModal}
      />
      <Table
        title="Projects"
        columns={columns}
        tableRef={tableRef}
        data={data}
        openEditModal={handleOpenEditModal}
        handleData={handleSelectedData}
        showEditAction
        openDeleteModal={handleOpenDeleteModal}
        showDeleteAction
        actions={actions}
        openFilter={handleShowFilter}
        showFilterAction
        onLoad={handleLoad}
      />
      <ResponseSnackbar
        open={openSnackbar}
        message={snackbar.message}
        success={snackbar.color}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};
export default ProjectList;
