import {makeStyles} from '@material-ui/core/styles';

const useQuadrantStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    padding: 26,
  },
  arrow: {
    '&::after': {
      background: '#ebe2ff',
    },
    width: '100%',
  },
  sheet: {
    background: '#ebe2ff',
  },
}));

export default useQuadrantStyles;
