import {Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, {useEffect, useState} from 'react';
import ResponseSnackbar from '../../components/ResponseSnackbar';
import useFetch from '../../useFetch';
import EditSettings from './EditSettings';
import useSettingsStyles from './useSettingsStyles';

const Settings = () => {
  const classes = useSettingsStyles();
  const [settings, setSettings] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: '',
    color: '',
  });

  const getSettingsData = async () => {
    await useFetch(`/settings`, 'GET')
      .then(response => response.json())
      .then(data => setSettings(data));
  };

  useEffect(() => {
    getSettingsData();
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    useFetch(`/settings`, 'POST', values).then(response => {
      if (response.status >= 200 && response.status < 300) {
        setSubmitting(false);
        setSnackbar({
          message: `The settings were edited successfully.`,
          color: 'success',
        });
        setOpenSnackbar(true);
      } else {
        response
          .json()
          .then(data => {
            throw Error(JSON.stringify(data));
          })
          .catch(() => {
            setSnackbar({
              message: `The settings could not be edited.`,
              color: 'error',
            });
            setOpenSnackbar(true);
          });
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <Grid container justify="flex-start">
        <Grid item xs={12}>
          <Typography className={classes.title}>Settings</Typography>
        </Grid>
        <Grid item xs={12}>
          <EditSettings onSubmit={handleSubmit} settings={settings} />
        </Grid>
      </Grid>
      <ResponseSnackbar
        open={openSnackbar}
        message={snackbar.message}
        success={snackbar.color}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Settings;
