import React from 'react';
import {List, Tooltip, ListItemIcon} from '@material-ui/core';
import {People, Settings, Equalizer, TrendingUp, Work, BorderAll, Help} from '@material-ui/icons';
import {NavLink} from 'react-router-dom';
import {useNavigatorStyles} from './useStyles';
import './Sidebar.css';

const Navigator = () => {
  const classes = useNavigatorStyles();

  return (
    <List className={classes.root} id="scrollbar">
      <Tooltip title="Projects" placement="left">
        <NavLink to="/projects" className={classes.menuButton}>
          <ListItemIcon className={classes.menuItem}>
            <Work className={classes.menuIcon} />
          </ListItemIcon>
        </NavLink>
      </Tooltip>

      <Tooltip title="Metrics" placement="left">
        <NavLink to="/metrics" className={classes.menuButton}>
          <ListItemIcon className={classes.menuItem}>
            <Equalizer className={classes.menuIcon} />
          </ListItemIcon>
        </NavLink>
      </Tooltip>

      <Tooltip title="Progress" placement="left">
        <NavLink to="/progress" className={classes.menuButton}>
          <ListItemIcon className={classes.menuItem}>
            <TrendingUp className={classes.menuIcon} />
          </ListItemIcon>
        </NavLink>
      </Tooltip>

      <Tooltip title="Quadrant" placement="left">
        <NavLink to="/quadrant" className={classes.menuButton}>
          <ListItemIcon className={classes.menuItem}>
            <BorderAll className={classes.menuIcon} />
          </ListItemIcon>
        </NavLink>
      </Tooltip>

      <Tooltip title="Squads" placement="left">
        <NavLink to="/squads" className={classes.menuButton}>
          <ListItemIcon className={classes.menuItem}>
            <People className={classes.menuIcon} />
          </ListItemIcon>
        </NavLink>
      </Tooltip>

      <Tooltip title="Settings" placement="left">
        <NavLink to="/settings" className={classes.menuButton}>
          <ListItemIcon className={classes.menuItem}>
            <Settings className={classes.menuIcon} />
          </ListItemIcon>
        </NavLink>
      </Tooltip>

      <Tooltip title="Help" placement="left">
        <NavLink to="/help" className={classes.menuButton}>
          <ListItemIcon className={classes.menuItem}>
            <Help className={classes.menuIcon} />
          </ListItemIcon>
        </NavLink>
      </Tooltip>
    </List>
  );
};

export default Navigator;
