import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2.5),
  },
  formControl: {
    minWidth: 195,
  },
}));

export default useStyles;
