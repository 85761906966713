import React from 'react';
import {CallbackComponent} from 'redux-oidc';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import jwtDecode from 'jwt-decode';
import userManager from '../../utils/userManager';
import setToken from '../../state/actions/setToken';
import {loginRedirectPath} from '../../utils/constants';

const CallbackPage = () => {
  const user = useSelector(state => state.oidc.user);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <CallbackComponent
        userManager={userManager}
        successCallback={() => {
          const redirectUrl = window.sessionStorage.getItem(loginRedirectPath) || '/';
          window.sessionStorage.removeItem(loginRedirectPath);
          sessionStorage.setItem('token', `Bearer ${user.access_token}`);
          dispatch(setToken(`Bearer ${user.access_token}`));
          const decoded = jwtDecode(user.access_token);
          sessionStorage.setItem('upn', decoded.upn || '');
          sessionStorage.setItem('given_name', decoded.given_name || '');
          sessionStorage.setItem('family_name', decoded.family_name || '');
          history.push(redirectUrl);
        }}
        errorCallback={error => {
          history.push('/');
          // eslint-disable-next-line no-console
          console.error(error);
        }}
      >
        <div>Redirecting...</div>
      </CallbackComponent>
    </>
  );
};

export default CallbackPage;
