import useFetch from '../../useFetch';
import errorCodes from '../../utils/errorCodes';
import getRequestUrl from '../../utils/getRequestUrl';
import {stringDateLocalToUTC, stringDateUTCToLocal} from '../../utils/dateUtils';

export const getProject = async (id, {setProject, setFound}) => {
  useFetch(`/projects/${id}`, 'GET')
    .then(response => response.json())
    .then(data => setProject(data))
    .catch(() => {
      setProject(null);
      setFound(false);
    });
};

export const getSquads = async ({setSquadList}) => {
  useFetch('/squads', 'GET')
    .then(data => data.json())
    .then(data => {
      setSquadList(data);
    });
};

export const updateProject = (
  values,
  handleRefreshData,
  setSubmitting,
  {setOpenEditModal, setSnackbar, setOpenSnackbar}
) => {
  useFetch(`/projects/${values.id}`, 'PUT', values).then(response => {
    if (response.status >= 200 && response.status < 300) {
      setSubmitting(false);
      setOpenEditModal(false);
      setSnackbar({
        message: `The project was edited successfully.`,
        color: 'success',
      });
      setOpenSnackbar(true);
      handleRefreshData();
    } else {
      response
        .json()
        .then(data => {
          throw Error(JSON.stringify(data));
        })
        .catch(err => {
          const errorMessage = err.message.includes(errorCodes.ALREADY_EXISTS)
            ? `The project could not be edited. This project title already exists.`
            : `The project could not be edited.`;
          setSnackbar({
            message: errorMessage,
            color: 'error',
          });
          setOpenSnackbar(true);
        });
      setSubmitting(false);
    }
  });
};

export const createDeployment = (
  values,
  id,
  setSubmitting,
  handleRefreshData,
  tableRef,
  refreshProgressChart,
  {setOpenDeploymentCreate, setSnackbar, setOpenSnackbar, setRefreshProgressChart}
) => {
  const valuesToReturn = {
    ...values,
    isDefect: values.isDefect === 'Yes',
    date: stringDateLocalToUTC(values.date),
  };

  useFetch(`/projects/${id}/deployments`, 'POST', valuesToReturn).then(response => {
    if (response.status >= 200 && response.status < 300) {
      setSubmitting(false);
      setOpenDeploymentCreate(false);
      setSnackbar({
        message: `The deployment was added successfully.`,
        color: 'success',
      });
      setOpenSnackbar(true);
      handleRefreshData();
      tableRef.current.onQueryChange();
      setRefreshProgressChart(!refreshProgressChart);
    } else {
      response
        .json()
        .then(data => {
          throw Error(JSON.stringify(data));
        })
        .catch(() => {
          setSnackbar({
            message: `The deployment could not be added.`,
            color: 'error',
          });
          setOpenSnackbar(true);
        });
      setSubmitting(false);
    }
  });
};

export const deleteProject = (
  values,
  setSubmitting,
  history,
  {setSnackbar, setOpenSnackbar, setOpenDeleteModal}
) => {
  useFetch(`/projects/${values}`, 'DELETE')
    .then(() => {
      history.push('/projects');
    })
    .catch(() => {
      setSnackbar({
        message: 'The project could not be deleted.',
        color: 'error',
      });
      setOpenSnackbar(true);
    });
  setSubmitting(false);
  setOpenDeleteModal(false);
};

export const editDeployment = (
  values,
  id,
  setSubmitting,
  tableRef,
  refreshProgressChart,
  {setOpenEditDeploymentModal, setRefreshProgressChart, setSnackbar, setOpenSnackbar}
) => {
  const valuesToReturn = {
    ...values,
    isDefect: values.isDefect === 'Yes',
    date: stringDateLocalToUTC(values.date),
  };

  useFetch(`/projects/${id}/deployments/${valuesToReturn.id}`, 'PUT', valuesToReturn).then(
    response => {
      if (response.status >= 200 && response.status < 300) {
        setSubmitting(false);
        setOpenEditDeploymentModal(false);
        setSnackbar({
          message: `The deployment was edited successfully.`,
          color: 'success',
        });
        setOpenSnackbar(true);
        tableRef.current.onQueryChange();
        setRefreshProgressChart(!refreshProgressChart);
      } else {
        response
          .json()
          .then(data => {
            throw Error(JSON.stringify(data));
          })
          .catch(() => {
            setSnackbar({
              message: `Deployment could not be edited.`,
              color: 'error',
            });
            setOpenSnackbar(true);
          });
        setSubmitting(false);
      }
    }
  );
};

export const deleteDeployment = (
  values,
  id,
  setSubmitting,
  tableRef,
  refreshProgressChart,
  handleRefreshData,
  {setOpenDeleteDeploymentModal, setRefreshProgressChart, setSnackbar, setOpenSnackbar}
) => {
  useFetch(`/projects/${id}/deployments/${values}`, 'DELETE').then(response => {
    if (response.status >= 200 && response.status < 300) {
      setSubmitting(false);
      setOpenDeleteDeploymentModal(false);
      setSnackbar({
        message: `The deployment was deleted successfully.`,
        color: 'success',
      });
      setOpenSnackbar(true);
      handleRefreshData();
      setRefreshProgressChart(!refreshProgressChart);
      if (tableRef.current.state.data.length - 1 === 0 && tableRef.current.state.query.page > 0) {
        // eslint-disable-next-line no-param-reassign
        tableRef.current.state.query.page -= 1;
      }
      tableRef.current.onQueryChange();
    } else {
      response
        .json()
        .then(data => {
          throw Error(JSON.stringify(data));
        })
        .catch(() => {
          setSnackbar({
            message: `The deployment could not be deleted.`,
            color: 'error',
          });
          setOpenSnackbar(true);
        });
      setSubmitting(false);
    }
  });
};

export const getDeployments = async (query, id, filters) => {
  const url = getRequestUrl(
    `/projects/${id}/deployments?limit=${query.pageSize}&page=${query.page}`,
    filters
  );
  const response = await useFetch(url, 'GET');
  const jsonData = await response.json();
  const {headers} = response;
  return {
    data: jsonData.map(deployment => {
      return {
        ...deployment,
        isDefect: deployment.isDefect ? 'Yes' : 'No',
        date: stringDateUTCToLocal(deployment.date),
      };
    }),
    page: query.page,
    totalCount: parseInt(headers.get('X-Total-Count') || 0, 10),
  };
};
