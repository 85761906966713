import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {MuiThemeProvider} from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {useSelector} from 'react-redux';
import {OidcProvider} from 'redux-oidc';
import Login from './pages/Login/Login';
import CallbackPage from './pages/Callback';
import './App.css';
import Layout from './Layout';
import userManager from './utils/userManager';
import store from './state/store';
import {loginRedirectPath, loginCallbackPath, color} from './utils/constants';

const theme = createMuiTheme({
  palette: {
    primary: {main: color},
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: color,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: color,
        },
      },
    },
  },
});

export default function App() {
  const token = useSelector(state => state.token);
  const {location} = window;
  if (!token && location.pathname !== loginCallbackPath) {
    window.sessionStorage.setItem(
      loginRedirectPath,
      `${location.pathname}${location.search}${location.hash}`
    );
  }

  return (
    <>
      <OidcProvider store={store} userManager={userManager}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/callback">
                <CallbackPage />
              </Route>
              <Route path="/">{token ? <Layout /> : <Redirect to="/login" />}</Route>
            </Switch>
          </Router>
        </MuiThemeProvider>
      </OidcProvider>
    </>
  );
}
