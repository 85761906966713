import {SET_JWT_TOKEN} from '../actionTypes';

const initialState = null;

export default function tokenReducer(state = initialState, action = {}) {
  if (action.type === SET_JWT_TOKEN) {
    return action.payload;
  }
  return state;
}
