const deploymentGridColumns = [
  {
    title: 'Deployment date',
    field: 'date',
  },
  {
    title: 'Notes',
    field: 'notes',
  },
  {
    title: 'Had a bug?',
    field: 'isDefect',
  },
  {
    title: 'Lead time',
    field: 'leadTime',
  },
  {
    title: 'Deployment duration',
    field: 'deploymentDuration',
  },
];

export default deploymentGridColumns;
