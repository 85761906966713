import React from 'react';
import PropTypes from 'prop-types';
import {TableCell, TableRow} from '@material-ui/core';
import {useFooterStyles} from './useStyles';

const RowFooter = ({row}) => {
  const footerClasses = useFooterStyles();
  return (
    <TableRow key={row.name}>
      <TableCell classes={{root: footerClasses.root}} />
      <TableCell classes={{root: footerClasses.root}}>{row.name} </TableCell>
      <TableCell classes={{root: footerClasses.root}} align="center">
        {row.deploymentFrequency.toFixed(2)}
      </TableCell>
      <TableCell classes={{root: footerClasses.root}} align="center">
        {row.leadTime.toFixed(2)}
      </TableCell>
      <TableCell classes={{root: footerClasses.root}} align="center">
        {row.deploymentDuration.toFixed(2)}
      </TableCell>
      <TableCell classes={{root: footerClasses.root}} align="center">
        {row.failureRate.toFixed(2)}
      </TableCell>
    </TableRow>
  );
};

RowFooter.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string,
    deploymentFrequency: PropTypes.number,
    leadTime: PropTypes.number,
    deploymentDuration: PropTypes.number,
    failureRate: PropTypes.number,
  }).isRequired,
};

export default RowFooter;
