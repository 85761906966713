import {makeStyles} from '@material-ui/core/styles';

const useFilterStyles = makeStyles(theme => ({
  dateField: {
    width: 215,
  },
  buttons: {
    margin: theme.spacing(1),
  },
  loading: {
    opacity: ({disabled}) => (!disabled ? 0.5 : 1),
  },
}));

export default useFilterStyles;
