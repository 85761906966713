import {makeStyles} from '@material-ui/core';

const useProgressStyles = makeStyles({
  legendRoot: {
    display: 'flex',
    margin: 'auto',
  },
  header: {
    backgroundColor: '#f6f6f6',
    minHeight: '40px',
    display: 'flex',
  },
  arrow: {
    '&::after': {
      background: '#ebe2ff',
    },
    width: '100%',
  },
  sheet: {
    background: '#ebe2ff',
  },
  tooltipDate: {
    color: 'grey',
  },
  tooltipValue: {
    textAlign: 'center',
  },
  padding: {
    paddingLeft: 13,
    paddingRight: 13,
    paddingBottom: 13,
  },
});

export default useProgressStyles;
