import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {CircularProgress, Typography} from '@material-ui/core';
import React, {createRef, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import EditIcon from '@material-ui/icons/Edit';
import isEmpty from 'lodash.isempty';
import ProgressChart from '../../components/ProgressChart';
import CustomFilter from '../../components/CustomFilter';
import ResponseSnackbar from '../../components/ResponseSnackbar';
import ProjectDetails from './ProjectDetails';
import CreateDeployment from './CreateDeployment';
import Table from '../../components/Table';
import deploymentGridColumns from './deploymentGridColumns';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import EditDeployment from './EditDeployment';
import {useProjectStyles} from './useStyles';
import EditProject from './EditProject';
import * as api from './apiFunctions';

const tableRef = createRef();

const Project = () => {
  const {id} = useParams();
  const history = useHistory();

  const classes = useProjectStyles();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteDeploymentModal, setOpenDeleteDeploymentModal] = useState(false);
  const [openDeploymentCreate, setOpenDeploymentCreate] = useState(false);
  const [openEditDeploymentModal, setOpenEditDeploymentModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedDeployment, setSelectedDeployment] = useState({});
  const [project, setProject] = useState(null);
  const [refreshProgressChart, setRefreshProgressChart] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: '',
    color: '',
  });
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateTo: '',
  });
  const [found, setFound] = useState(true);
  const [squadList, setSquadList] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const setters = {
    setOpenDeleteModal,
    setOpenEditModal,
    setOpenDeleteDeploymentModal,
    setOpenDeploymentCreate,
    setOpenEditDeploymentModal,
    setOpenSnackbar,
    setSelectedDeployment,
    setProject,
    setRefreshProgressChart,
    setSnackbar,
    setSquadList,
    setFound,
    setHasLoaded,
  };

  const getProjectData = async () => {
    await api.getProject(id, setters);
  };

  const getSquadList = async () => {
    if (openEditModal) {
      await api.getSquads(setters);
    }
  };

  useEffect(() => {
    getSquadList();
  }, [openEditModal]);

  useEffect(() => {
    getProjectData();
  }, []);

  const handleRefreshData = () => {
    getProjectData();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleEditSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    api.updateProject(values, handleRefreshData, setSubmitting, setters);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleOpenDeploymentCreate = () => {
    setOpenDeploymentCreate(true);
  };

  const handleCloseDeploymentCreate = () => {
    setOpenDeploymentCreate(false);
  };

  const handleFilterSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    setFilters({
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
    });
    tableRef.current.onQueryChange();
    setSubmitting(false);
  };

  const handleFilterClear = resetForm => {
    resetForm({});
    setFilters({
      dateTo: '',
      dateFrom: '',
    });
    tableRef.current.onQueryChange();
  };

  const handleDeploymentCreate = (values, {setSubmitting}) => {
    setSubmitting(true);
    api.createDeployment(
      values,
      id,
      setSubmitting,
      handleRefreshData,
      tableRef,
      refreshProgressChart,
      setters
    );
  };

  const handleDeleteSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    api.deleteProject(values, setSubmitting, history, setters);
  };

  const handleOpenEditDeploymentModal = () => {
    setOpenEditDeploymentModal(true);
  };

  const handleSelectData = data => {
    setSelectedDeployment(data);
  };

  const handleCloseEditDeploymentModal = () => {
    setOpenEditDeploymentModal(false);
  };

  const handleEditDeploymentSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    api.editDeployment(values, id, setSubmitting, tableRef, refreshProgressChart, setters);
  };

  const handleCloseDeleteDeploymentModal = () => {
    setOpenDeleteDeploymentModal(false);
  };

  const handleOpenDeleteDeploymentModal = () => {
    setOpenDeleteDeploymentModal(true);
  };

  const handleDeleteDeploymentSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    api.deleteDeployment(
      values,
      id,
      setSubmitting,
      tableRef,
      refreshProgressChart,
      handleRefreshData,
      setters
    );
  };

  const handleLoad = didLoad => {
    setHasLoaded(didLoad);
  };

  const deploymentData = query => {
    return api.getDeployments(query, id, filters);
  };

  if (project && found) {
    return (
      <>
        <Grid container justify="flex-start" spacing={2} alignItems="flex-start">
          <Grid item container justify="flex-start" sm={12} md={5}>
            <Grid item>
              <Typography className={classes.title}>Project Details</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleOpenEditModal}
                disabled={isEmpty(project)}
              >
                Edit
              </Button>
              <EditProject
                open={openEditModal}
                onClose={handleCloseEditModal}
                onSubmit={handleEditSubmit}
                project={project}
                squadList={squadList}
              />
            </Grid>
          </Grid>
          <Grid item container justify="flex-end" xs={12} md={7} spacing={1}>
            <Grid item md="auto" xs={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenDeploymentCreate}
                disabled={isEmpty(project)}
              >
                Create Deployment
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <CreateDeployment
          onSubmit={handleDeploymentCreate}
          onClose={handleCloseDeploymentCreate}
          open={openDeploymentCreate}
        />

        <Grid item>
          <ProjectDetails key={project.id} project={project} />
        </Grid>
        <Grid item className={classes.space}>
          <CustomFilter
            onClear={handleFilterClear}
            onSubmit={handleFilterSubmit}
            title="Select Date/Time"
            hasLoaded={hasLoaded}
          />
        </Grid>

        <Box mt={5} mb={5}>
          <ProgressChart
            projectId={parseInt(id, 10)}
            filters={filters}
            hasLoaded={hasLoaded}
            onLoad={handleLoad}
            handleRefresh={refreshProgressChart}
          />
        </Box>
        <Box mt={5} mb={5}>
          <Table
            title="Deployments"
            columns={deploymentGridColumns}
            tableRef={tableRef}
            data={deploymentData}
            openEditModal={handleOpenEditDeploymentModal}
            showEditAction
            handleData={handleSelectData}
            openDeleteModal={handleOpenDeleteDeploymentModal}
            showDeleteAction
          />
        </Box>
        <EditDeployment
          onSubmit={handleEditDeploymentSubmit}
          onClose={handleCloseEditDeploymentModal}
          open={openEditDeploymentModal}
          deployment={selectedDeployment}
        />
        <DeleteDialog
          onSubmit={handleDeleteDeploymentSubmit}
          onClose={handleCloseDeleteDeploymentModal}
          id={selectedDeployment.id}
          open={openDeleteDeploymentModal}
          entityName="this deployment"
        />
        <ResponseSnackbar
          open={openSnackbar}
          message={snackbar.message}
          success={snackbar.color}
          onClose={handleCloseSnackbar}
        />
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
            onClick={handleOpenDeleteModal}
            disabled={isEmpty(project)}
          >
            Delete project
          </Button>

          <DeleteDialog
            onSubmit={handleDeleteSubmit}
            onClose={handleCloseDeleteModal}
            open={openDeleteModal}
            id={project.id}
            entityName={project.title}
          />
        </Grid>
      </>
    );
  }
  if (!found) {
    return <h2>Project not found.</h2>;
  }
  return (
    <Grid container alignItems="center" justify="center" spacing={2}>
      <CircularProgress />
    </Grid>
  );
};

export default Project;
