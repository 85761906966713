import {
  Container,
  Grid,
  Button,
  ButtonGroup,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Typography,
  Paper,
} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {
  Chart,
  ArgumentAxis,
  Legend,
  ValueAxis,
  Title,
  Tooltip,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import PropTypes from 'prop-types';
import {ArgumentScale, EventTracker} from '@devexpress/dx-react-chart';
import {scaleBand} from '@devexpress/dx-chart-core';
import useProgressStyles from './useProgressStyles';
import TooltipSheet from '../TooltipSheet';
import TooltipArrow from '../TooltipArrow';
import useFetch from '../../useFetch';
import getRequestUrl from '../../utils/getRequestUrl';
import {takePartOfDate, calculateIfDateIntervalTooLarge} from '../../utils/dateUtils';

const ProgressChart = ({
  projectId,
  onLoad,
  hasLoaded,
  filters: {dateFrom, dateTo, squadIds, projectIds},
  handleRefresh,
}) => {
  const classes = useProgressStyles();

  const [data, setData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [chart, setChart] = useState('deployment-frequency');
  const [period, setPeriod] = useState('day');
  const [title, setTitle] = useState(`Deployment frequency, deployments/${period}`);
  const [warningMessage, setWarningMessage] = useState('');
  const globalUrl = `/global/progress/${chart}?period=${period}`;
  const singleProjectUrl = `/projects/${projectId}/progress/${chart}?period=${period}`;

  const handleLoad = didLoad => {
    onLoad(didLoad);
  };

  const fetchData = async url => {
    const response = await useFetch(url, 'GET');
    const jsonData = await response.json();
    const projectsTemp = [];

    if (jsonData && jsonData.length > 0) {
      Object.getOwnPropertyNames(jsonData['0']).forEach(prop =>
        prop !== 'date' ? projectsTemp.push({name: prop}) : null
      );
    }
    setProjects(projectsTemp);

    const dataToSet = jsonData.map(record => {
      return {
        ...record,
        dateTooltip: record.date,
        date: takePartOfDate(record.date, period),
        median: record.median ? record.median : 0,
      };
    });
    setData(dataToSet);
    handleLoad(true);
  };

  useEffect(() => {
    handleLoad(false);
    if (projectId) {
      const url = getRequestUrl(singleProjectUrl, {dateFrom, dateTo});
      fetchData(url);
    } else {
      const url = getRequestUrl(globalUrl, {dateFrom, dateTo, squadIds, projectIds});
      fetchData(url);
    }
    setWarningMessage(calculateIfDateIntervalTooLarge(dateTo, dateFrom, period));
  }, [chart, period, projectId, squadIds, projectIds, dateTo, dateFrom, handleRefresh]);

  const Content = ({targetItem, text}) => {
    const whichDate = targetItem.point;
    const number = Number.parseFloat(text).toFixed(2);
    Content.propTypes = {
      targetItem: PropTypes.shape({
        point: PropTypes.number,
      }),
      text: PropTypes.string,
    };

    Content.defaultProps = {
      targetItem: null,
      text: '',
    };

    return (
      <>
        {period === 'day' ? (
          <Tooltip.Content text={data[whichDate].dateTooltip} className={classes.tooltipDate} />
        ) : null}
        <Tooltip.Content text={number} className={classes.tooltipValue} />
      </>
    );
  };

  /* eslint-disable react/jsx-props-no-spreading */
  const LegendRoot = props => {
    return <Legend.Root {...props} className={classes.legendRoot} />;
  };
  /* eslint-enable */

  const GridChart = () => {
    return data.length > 1 && projects.length > 0 ? <GridChartWithData /> : <GridChartEmpty />;
  };

  const GridChartWithData = () => {
    return (
      <Grid item xs={12} className={classes.padding}>
        <Chart data={data}>
          <ArgumentAxis showGrid showLabels={data.length <= 31} />
          <ArgumentScale factory={scaleBand} />
          <Legend rootComponent={LegendRoot} position="bottom" />
          <Title text={title} />
          <EventTracker />
          <Tooltip
            sheetComponent={TooltipSheet}
            arrowComponent={TooltipArrow}
            contentComponent={Content}
          />
          <ValueAxis />
          {projects.map(s => (
            <LineSeries name={s.name} valueField={s.name} argumentField="date" />
          ))}
        </Chart>
      </Grid>
    );
  };

  const GridChartEmpty = () => {
    return (
      <Grid container item xs={12} alignItems="center" justify="center">
        <Chart data={[]}>
          <Typography variant="h5">
            The date interval is too small to display data for this period. Need at least 2
            datapoints to draw a line.
          </Typography>
        </Chart>
      </Grid>
    );
  };

  const GridChartLoading = () => {
    return (
      <Grid container item xs={12} alignItems="center" justify="center">
        <Chart data={[]}>
          <CircularProgress />
        </Chart>
      </Grid>
    );
  };

  return (
    <>
      <Container>
        <Paper square>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box className={classes.header}>
                <ButtonGroup variant="text" fullWidth>
                  <Button
                    onClick={() => {
                      setChart('deployment-frequency');
                      setTitle(`Deployment frequency, deployments/${period}`);
                    }}
                  >
                    Deployment frequency
                  </Button>
                  <Button
                    onClick={() => {
                      setChart('lead-time');
                      setTitle('Lead time, minutes');
                    }}
                  >
                    Lead time
                  </Button>
                  <Button
                    onClick={() => {
                      setChart('deployment-duration');
                      setTitle('Deployment duration, minutes');
                    }}
                  >
                    Deployment duration
                  </Button>
                  <Button
                    onClick={() => {
                      setChart('failure-rate');
                      setTitle('Failure rate, %');
                    }}
                  >
                    Failure rate
                  </Button>
                </ButtonGroup>
              </Box>
            </Grid>
            {hasLoaded ? <GridChart /> : <GridChartLoading />}
            <Grid item xs={12} className={classes.padding}>
              <RadioGroup
                row
                defaultValue="month"
                value={period}
                onChange={e => {
                  setPeriod(e.target.value);
                }}
              >
                <FormControlLabel value="day" control={<Radio color="primary" />} label="Day" />
                <FormControlLabel value="month" control={<Radio color="primary" />} label="Month" />
                <FormControlLabel value="year" control={<Radio color="primary" />} label="Year" />
              </RadioGroup>
              <Typography color="primary" variant="body2">
                {warningMessage}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

ProgressChart.propTypes = {
  projectId: PropTypes.number,
  filters: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    squadIds: PropTypes.arrayOf(PropTypes.number),
    projectIds: PropTypes.arrayOf(PropTypes.number),
  }),
  onLoad: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool,
  handleRefresh: PropTypes.bool,
};

ProgressChart.defaultProps = {
  projectId: null,
  filters: {
    dateFrom: '',
    dateTo: '',
    squadIds: [],
    projectIds: [],
  },
  hasLoaded: false,
  handleRefresh: false,
};

export default ProgressChart;
