import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import ProjectGridRow from './ProjectGridRow';
import {useProjectDetailsStyles} from './useStyles';

const ProjectDetails = ({project}) => {
  const classes = useProjectDetailsStyles();
  const {title, deploymentCount, notes, squadName} = project;

  return (
    <>
      <Grid container className={classes.root} spacing={6} direction="row">
        <Grid container item xs={12} lg={6} className={classes.root}>
          <ProjectGridRow heading="Title" content={title} />
          <ProjectGridRow heading="Squad" content={squadName} />
          <ProjectGridRow heading="Deployment Count" content={deploymentCount.toString()} />
        </Grid>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12} lg={12}>
            <Typography className={classes.heading}>Notes</Typography>
            <Typography className={classes.notesBox}>{notes}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
ProjectDetails.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    squadName: PropTypes.string,
    notes: PropTypes.string,
    squadId: PropTypes.number,
    deploymentCount: PropTypes.number,
  }),
};

ProjectDetails.defaultProps = {
  project: {
    title: '',
    id: null,
    squadName: '',
    notes: '',
    squadId: null,
    deploymentCount: '',
  },
};

export default ProjectDetails;
