import React, {createRef, useState} from 'react';
import {Box, Button, Grid} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Table from '../../components/Table/Table';
import CreateSquad from './CreateSquad';
import EditSquad from './EditSquad';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import ResponseSnackbar from '../../components/ResponseSnackbar';
import useFetch from '../../useFetch';
import errorCodes from '../../utils/errorCodes';

const tableRef = createRef();

const SquadList = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedSquad, setSelectedSquad] = useState({});
  const [snackbar, setSnackbar] = useState({
    message: '',
    color: '',
  });

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSelectedData = data => {
    setSelectedSquad(data);
  };

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleEditSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    useFetch(`/squads/${values.id}`, 'PUT', values).then(response => {
      if (response.status >= 200 && response.status < 300) {
        setSubmitting(false);
        setOpenEditModal(false);
        setSnackbar({
          message: `The squad was edited successfully.`,
          color: 'success',
        });
        setOpenSnackbar(true);
        tableRef.current.onQueryChange();
      } else {
        response
          .json()
          .then(data => {
            throw Error(JSON.stringify(data));
          })
          .catch(err => {
            const errorMessage = err.message.includes(errorCodes.ALREADY_EXISTS)
              ? `The squad could not be edited. This squad name already exists.`
              : `The squad could not be edited.`;
            setSnackbar({
              message: errorMessage,
              color: 'error',
            });
            setOpenSnackbar(true);
          });
        setSubmitting(false);
      }
    });
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleDeleteDialogClosed = () => {
    setOpenDeleteModal(false);
  };

  const handleCreateSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    useFetch('/squads', 'POST', values).then(response => {
      if (response.status >= 200 && response.status < 300) {
        setSubmitting(false);
        setOpenCreateModal(false);
        setSnackbar({
          message: `The squad was added successfully.`,
          color: 'success',
        });
        setOpenSnackbar(true);
        tableRef.current.onQueryChange();
      } else {
        response
          .json()
          .then(data => {
            throw Error(JSON.stringify(data));
          })
          .catch(err => {
            const errorMessage = err.message.includes(errorCodes.ALREADY_EXISTS)
              ? `The squad could not be added. This squad name already exists.`
              : `The squad could not be added.`;
            setSnackbar({
              message: errorMessage,
              color: 'error',
            });
            setOpenSnackbar(true);
          });
        setSubmitting(false);
      }
    });
  };

  const handleDeleteSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    useFetch(`/squads/${values}`, 'DELETE').then(response => {
      if (response.status === 200) {
        response.json().then(() => {
          setSnackbar({
            message: `The squad was deleted successfully.`,
            color: 'success',
          });
          setOpenSnackbar(true);
          if (
            tableRef.current.state.data.length - 1 === 0 &&
            tableRef.current.state.query.page > 0
          ) {
            tableRef.current.state.query.page -= 1;
          }
          tableRef.current.onQueryChange();
        });
      } else if (!response.ok) {
        response.json().then(data => {
          setSnackbar({
            message: data.message || 'The squad could not be deleted.',
            color: 'error',
          });
          setOpenSnackbar(true);
        });
      }
    });
    setSubmitting(false);
    setOpenDeleteModal(false);
  };

  const data = async query => {
    const url = `/squads?limit=${query.pageSize}&page=${query.page}`;
    const response = await useFetch(url, 'GET');
    const jsonData = await response.json();
    const {headers} = response;
    return {
      data: jsonData,
      page: query.page,
      totalCount: parseInt(headers.get('X-Total-Count') || 0, 10),
    };
  };

  return (
    <>
      <Grid container justify="flex-end">
        <Box mb={5}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenCreateModal}
          >
            Create Squad
          </Button>
        </Box>
      </Grid>
      <CreateSquad
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        onSubmit={handleCreateSubmit}
      />
      <EditSquad
        onSubmit={handleEditSubmit}
        onClose={handleCloseEditModal}
        open={openEditModal}
        squad={selectedSquad}
      />
      <DeleteDialog
        onSubmit={handleDeleteSubmit}
        onClose={handleDeleteDialogClosed}
        id={selectedSquad.id}
        entityName={selectedSquad.name}
        open={openDeleteModal}
      />
      <Table
        title="Squads"
        columns={columns}
        tableRef={tableRef}
        data={data}
        openEditModal={handleOpenEditModal}
        handleData={handleSelectedData}
        showEditAction
        openDeleteModal={handleOpenDeleteModal}
        showDeleteAction
      />
      <ResponseSnackbar
        open={openSnackbar}
        message={snackbar.message}
        success={snackbar.color}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};
export default SquadList;
