import {makeStyles} from '@material-ui/core/styles';

export const useFooterStyles = makeStyles({
  root: {
    color: 'black',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    backgroundColor: '#f6f6f6',
    fontWeight: 'bold',
  },
});

export const useTableStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  loading: {
    opacity: ({disabled}) => (!disabled ? 0.5 : 1),
  },
  spinner: {
    textAlign: 'center',
    paddingTop: '50px',
    paddingBottom: '50px',
  },
});

export const usePaginationStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export const useHeaderStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
  greyBackground: {
    backgroundColor: 'rgb(204, 204, 204)',
  },
  metricsFontSize: {
    fontSize: '1.4rem',
  },
  purpleBackground: {
    backgroundColor: `#f6f6f6`,
  },
});

export const useRowBodyStyles = makeStyles({
  border: {borderWidth: 'thick', borderColor: '#32363f'},
});
