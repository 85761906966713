import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import {useProjectGridRowStyles} from './useStyles';

const ProjectGridRow = ({content, heading}) => {
  const classes = useProjectGridRowStyles();
  return (
    <Grid container item xs={12} className={classes.root} spacing={4}>
      <Grid item xs={3}>
        <Typography className={classes.heading}>{heading}:</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography className={classes.content}>{content}</Typography>
      </Grid>
    </Grid>
  );
};
ProjectGridRow.defaultProps = {
  content: '',
};

ProjectGridRow.propTypes = {
  content: PropTypes.string,
  heading: PropTypes.string.isRequired,
};

export default ProjectGridRow;
