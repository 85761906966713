import {makeStyles} from '@material-ui/core/styles';

const useMultiSelectStyles = makeStyles(() => ({
  selectForm: {
    minWidth: 120,
    maxWidth: 240,
  },
}));

export default useMultiSelectStyles;
