import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import {Form, Formik} from 'formik';
import DialogHeader from '../DialogHeader';
import DialogFooter from '../DialogFooter';
import useDialogDeleteStyles from './useDialogDeleteStyles';

const DeleteDialog = ({open, onClose, onSubmit, entityName, id}) => {
  const classes = useDialogDeleteStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogHeader onClose={onClose} title="Delete content" />
      <Formik enableReinitialize onSubmit={onSubmit} initialValues={id}>
        {({isSubmitting}) => (
          <Form>
            <DialogContent className={classes.root}>
              <span>
                Are you sure you want to delete
                <span className={classes.entryTitle}>{entityName}</span>?
              </span>
            </DialogContent>
            <DialogFooter onClose={onClose} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

DeleteDialog.defaultProps = {
  entityName: '',
  id: null,
};

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  entityName: PropTypes.string,
  id: PropTypes.number,
};

export default DeleteDialog;
