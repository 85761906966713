import {makeStyles} from '@material-ui/core/styles';

const useDialogDeleteStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  entryTitle: {
    fontSize: 'large',
    color: 'blue',
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 700,
  },
}));

export default useDialogDeleteStyles;
