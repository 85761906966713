export default {
  '/': 'Global',
  '/help': 'Help',
  '/metrics': 'Metrics',
  '/quadrant': 'Quadrant',
  '/progress': 'Progress',
  '/projects': 'Projects',
  '/squads': 'Squads',
  '/settings': 'Settings',
  '/login': 'Login',
};
