import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import {Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';
import useSettingsStyles from './useSettingsStyles';

const EditSettings = ({onSubmit, settings: {id, failureRateLimit, deploymentFrequencyLimit}}) => {
  const classes = useSettingsStyles();

  const validationSchema = yup.object({
    failureRateLimit: yup
      .number()
      .min(0, 'Failure rate limit must be greater than or equal to 0')
      .max(100, 'Failure rate limit must be less than or equal to 100')
      .nullable(),
    deploymentFrequencyLimit: yup
      .number()
      .min(0, 'Deployment frequency limit must be greater than or equal to 0')
      .max(1000, 'Deployment frequency limit must be less than or equal to 1000')
      .nullable(),
  });

  const initialValues = {
    id: id || '',
    failureRateLimit: failureRateLimit || '',
    deploymentFrequencyLimit: deploymentFrequencyLimit || '',
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({values, errors, handleChange, isSubmitting}) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Quadrant settings</Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.paper}>
                <TextField
                  label="Failure rate limit (percentage)"
                  name="failureRateLimit"
                  type="number"
                  className={classes.field}
                  value={values.failureRateLimit}
                  onChange={handleChange}
                  error={!!errors.failureRateLimit}
                  helperText={errors.failureRateLimit}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.paper}>
                <TextField
                  label="Deployment frequency limit (deployments/day)"
                  name="deploymentFrequencyLimit"
                  type="number"
                  inputProps={{step: '0.01'}}
                  className={classes.field}
                  value={values.deploymentFrequencyLimit}
                  onChange={handleChange}
                  error={!!errors.deploymentFrequencyLimit}
                  helperText={errors.deploymentFrequencyLimit}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.paper}>
                <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EditSettings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    id: PropTypes.number,
    failureRateLimit: PropTypes.number,
    deploymentFrequencyLimit: PropTypes.number,
  }),
};

EditSettings.defaultProps = {
  settings: {
    id: null,
    failureRateLimit: null,
    deploymentFrequencyLimit: null,
  },
};

export default EditSettings;
