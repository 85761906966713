import {WebStorageStateStore} from 'oidc-client';
import {createUserManager} from 'redux-oidc';

const clientId = 'bbefb0c0-fdd8-42f5-9558-c8b31fc60d13'; // GUID Client Application ID
const tenantId = '70cfc546-6f88-4849-9581-0e892bf4557d'; // GUID Azure Tenant ID

const userManagerConfig = {
  authority: `https://login.microsoftonline.com/${tenantId}/v2.0/.well-known/openid-configuration`,
  client_id: clientId,
  redirect_uri: `${window.location.origin}/callback`,
  response_type: 'token id_token',
  scope: `openid https://devops-metrics.westeurope.azurecontainer.io/api.call`,
  silent_redirect_uri: `${window.location.origin}/silent_renew.html`,
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({store: window.localStorage}),
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: false,
  monitorSession: false,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
