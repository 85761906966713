import {makeStyles} from '@material-ui/core/styles';

const useDialogFooterStyles = makeStyles(theme => ({
  dialogActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
}));

export default useDialogFooterStyles;
