import {useField} from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import React from 'react';
import PropTypes from 'prop-types';

const CustomRadio = ({label, ...props}) => {
  const [field] = useField(props);
  return (
    <FormControlLabel
      name={field.name}
      value={field.value}
      type={field.type}
      checked={field.checked}
      onChange={field.onChange}
      onBlur={field.onBlur}
      control={<Radio color="primary" />}
      label={label}
    />
  );
};

CustomRadio.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
};

CustomRadio.defaultProps = {
  label: '',
};

export default CustomRadio;
