import {makeStyles} from '@material-ui/core/styles';

const useDialogHeaderStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,52,84,0.5)',
    paddingLeft: theme.spacing(4),
  },
  dialogExitButton: {
    marginLeft: 'auto',
    backgroundColor: 'rgba(255,52,84,0.5)',
    color: 'black',
  },
}));

export default useDialogHeaderStyles;
