import React from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import * as yup from 'yup';
import DialogFooter from '../../components/dialogs/DialogFooter';
import DialogHeader from '../../components/dialogs/DialogHeader';
import useSquadStyles from './useSquadStyles';

const EditSquad = ({open, onClose, onSubmit, squad: {name, id}}) => {
  const classes = useSquadStyles();

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Squad name is required')
      .trim('Squad name cannot be blank')
      .strict()
      .max(255, 'Squad name must have no more than 255 characters'),
  });

  const initialValues = {
    name,
    id,
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogHeader title="Edit squad" onClose={onClose} />
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({values, errors, handleChange, isSubmitting}) => (
          <Form>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.paper}>
                    <TextField
                      id="name"
                      name="name"
                      label="Squad name*"
                      value={values.name}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogFooter onClose={onClose} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditSquad.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  squad: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
};

EditSquad.defaultProps = {
  squad: {
    name: '',
    id: null,
  },
};

export default EditSquad;
