import React from 'react';
import {Typography, Tooltip} from '@material-ui/core';
import Navigator from './Navigator';
import UserSection from './UserSection';
import {useSidebarStyles} from './useStyles';
import {ReactComponent as Logo} from './logo.svg';

const Sidebar = () => {
  const classes = useSidebarStyles();
  return (
    <div className={classes.navigationSideBar}>
      <Tooltip title="Devbridge Metrics" placement="left">
        <Logo className={classes.logo} />
      </Tooltip>
      <Typography className={classes.typography}>Metrics</Typography>
      <Navigator />
      <UserSection />
    </div>
  );
};

export default Sidebar;
