import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Container, Grid, Paper, CircularProgress} from '@material-ui/core';
import {Animation, ArgumentScale, EventTracker, ValueScale} from '@devexpress/dx-react-chart';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  ScatterSeries,
  Tooltip,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import TooltipArrow from '../../components/TooltipArrow';
import useQuadrantStyles from './useQuadrantStyles';

const QuadrantChart = ({maxDeploymentAxis, quadrantData, hasLoaded}) => {
  const [point, setPoint] = useState(0);
  const classes = useQuadrantStyles();
  const deployAxisRange = () => [0, maxDeploymentAxis.toFixed(1)];
  const failAxisRange = () => [100, 0];
  const format = () => tick => `${tick}%`;

  /* eslint-disable react/jsx-props-no-spreading */
  const TooltipSheet = props => {
    if (!quadrantData[point].projectTitle || !quadrantData[point].squadName) {
      return <></>;
    }
    return <Tooltip.Sheet {...props} className={classes.sheet} />;
  };

  TooltipSheet.propTypes = {};

  const TooltipContent = () => {
    return (
      <table>
        <tbody>
          <tr>
            <td>
              <Tooltip.Content text="Squad:" />
            </td>
            <td>
              <Tooltip.Content text={quadrantData[point].squadName} />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip.Content text="Project:" />
            </td>
            <td>
              <Tooltip.Content text={quadrantData[point].projectTitle} />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip.Content text="Deployment freq.:" />
            </td>
            <td>
              <Tooltip.Content text={quadrantData[point].deploymentFrequency} />
            </td>
          </tr>
          <tr>
            <td>
              <Tooltip.Content text="Failure rate:" />
            </td>
            <td>
              <Tooltip.Content text={`${quadrantData[point].failureRate}%`} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Container>
        <Paper square className={classes.root}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={1} />
            <Grid item xs={10}>
              <div style={{textAlign: 'center'}}>
                <h3>Deployment frequency (deployments per day)</h3>
              </div>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1}>
              <div>
                <h3 style={{writingMode: 'vertical-rl', transform: 'rotateZ(180deg)'}}>
                  Failure rate
                </h3>
              </div>
            </Grid>
            {!hasLoaded ? (
              <Grid container item xs={10} alignItems="center" justify="center">
                <Chart data={[]}>
                  <CircularProgress />
                </Chart>
              </Grid>
            ) : (
              <Grid item xs={10}>
                <Chart data={quadrantData}>
                  <ArgumentScale modifyDomain={deployAxisRange} />
                  <ValueScale modifyDomain={failAxisRange} />
                  <ArgumentAxis showGrid />
                  <ValueAxis tickFormat={format} showGrid />
                  <ScatterSeries
                    valueField="failureRate"
                    argumentField="deploymentFrequency"
                    color="#746595"
                  />
                  <LineSeries
                    color="#a293c6"
                    valueField="failRateValueField"
                    argumentField="failRateArgumentField"
                  />
                  <LineSeries
                    color="#a293c6"
                    valueField="deployFreqValueField"
                    argumentField="deployFreqArgumentField"
                  />
                  <Animation />
                  <EventTracker />
                  <Tooltip
                    sheetComponent={TooltipSheet}
                    arrowComponent={TooltipArrow}
                    contentComponent={TooltipContent}
                    onTargetItemChange={element => {
                      if (element !== null) {
                        setPoint(element.point);
                      }
                    }}
                  />
                </Chart>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default QuadrantChart;

QuadrantChart.propTypes = {
  maxDeploymentAxis: PropTypes.number.isRequired,
  quadrantData: PropTypes.arrayOf(
    PropTypes.shape({
      squadName: PropTypes.string,
      projectTitle: PropTypes.string,
      deploymentFrequency: PropTypes.number,
      failureRate: PropTypes.number,
      failRateValueField: PropTypes.number,
      failRateArgumentField: PropTypes.number,
      deployFreqValueField: PropTypes.number,
      deployFreqArgumentField: PropTypes.number,
    })
  ).isRequired,
  hasLoaded: PropTypes.bool.isRequired,
};
