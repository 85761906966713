const mapSquadsToOptions = squadList => {
  return squadList.map(squad => {
    return {
      label: squad.name,
      value: squad.id,
    };
  });
};

export default mapSquadsToOptions;
