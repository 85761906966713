import {useEffect, useState} from 'react';
import useFetch from './useFetch';

const useData = (endpoint, id) => {
  const [data, setData] = useState(id === undefined ? [] : {});

  const loadData = async () => {
    let url;
    if (id === undefined) {
      url = `${endpoint}`;
    } else {
      url = `${endpoint}/${id}`;
    }
    let response;

    try {
      response = await useFetch(url, 'GET');
      if (!response.ok) {
        throw new Error(`Response was not okay: ${response.statusText}`);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message);
    }

    if (response !== undefined) {
      if (response.status === 200) {
        const jsonData = await response.json();
        setData(jsonData);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    loadData().catch(err => console.log(err.message));
  }, []);

  return data;
};

export default useData;
