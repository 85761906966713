import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TablePagination,
  TableBody as TableBodyMUI,
  TableCell,
  TableRow,
  CircularProgress,
  Paper,
} from '@material-ui/core';
import TableHeader from './TableHeader';
import TableBody from './CustomTableBody';
import TableFooter from './CustomTableFooter';
import TablePaginationActions from './TablePaginationActions';
import {useTableStyles} from './useStyles';

const MetricsTable = ({
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  hasLoaded,
}) => {
  const tableClasses = useTableStyles({disabled: hasLoaded});

  const TableWithMetrics = () => {
    return data.records.length !== 0 ? <TableWithMetricsWithData /> : <TableWithMetricsEmpty />;
  };

  const TableWithMetricsWithData = () => {
    return (
      <>
        <TableBody rowsPerPage={rowsPerPage} records={data.records} page={page} />
        <TableFooter stats={data.stats} />
      </>
    );
  };

  const TableWithMetricsEmpty = () => {
    return (
      <TableBodyMUI>
        <TableRow>
          <TableCell colSpan={6} align="center">
            No records to display
          </TableCell>
        </TableRow>
      </TableBodyMUI>
    );
  };

  const TableWithMetricsLoading = () => {
    return (
      <tbody>
        <tr>
          <td className={tableClasses.spinner} colSpan={6}>
            <CircularProgress />
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <Paper>
      <TableContainer component={Paper} className={tableClasses.loading}>
        <Table className={tableClasses.table} aria-label="metrics">
          <TableHeader />
          {hasLoaded ? <TableWithMetrics /> : <TableWithMetricsLoading />}
        </Table>
      </TableContainer>
      {hasLoaded ? (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          colSpan={3}
          count={data.records.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      ) : (
        <></>
      )}
    </Paper>
  );
};

MetricsTable.propTypes = {
  data: PropTypes.shape({
    records: PropTypes.arrayOf(
      PropTypes.shape({
        projectTitle: PropTypes.string,
        squadName: PropTypes.string,
        deploymentFrequency: PropTypes.number,
        leadTime: PropTypes.number,
        deploymentDuration: PropTypes.number,
        failureRate: PropTypes.number,
        slice: PropTypes.func,
        length: PropTypes.number,
      })
    ),
    stats: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        deploymentFrequency: PropTypes.number,
        leadTime: PropTypes.number,
        deploymentDuration: PropTypes.number,
        failureRate: PropTypes.number,
        map: PropTypes.func,
      })
    ),
    length: PropTypes.number,
  }).isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
};

export default MetricsTable;
