import isEmpty from 'lodash.isempty';
import setToken from './state/actions/setToken';
import store from './state/store';
import {API_URL} from './config/api';

const useFetch = (url, method, body = {}, headers = {}) => {
  const params = {
    method,
    headers: {
      ...headers,
      'content-type': 'application/json',
      Authorization: store.getState().token || sessionStorage.getItem('token'),
    },
  };

  if (!isEmpty(body)) {
    params.body = JSON.stringify(body);
  }

  const urlWithContext = `${API_URL}${url}`;

  return fetch(urlWithContext, params).then(response => {
    if (response.status === 401) {
      sessionStorage.clear();
      store.dispatch(setToken(null));
    } else if (response.status === 404) {
      throw Error();
    }

    return response;
  });
};

export default useFetch;
