import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Crumbs from './Crumbs';
import {useHeaderStyles} from './useStyles';

const Header = () => {
  const classes = useHeaderStyles();

  return (
    <AppBar className={classes.root}>
      <Crumbs />
    </AppBar>
  );
};

export default Header;
