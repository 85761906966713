import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Routes from './Routes';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import setToken from './state/actions/setToken';

const Layout = () => {
  const user = useSelector(state => state.oidc.user);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === null) {
      sessionStorage.clear();
      dispatch(setToken(null));
      history.push('/login');
    }
  }, [user]);
  return (
    <div className="App">
      <header>
        <Header />
      </header>

      <nav>
        <Sidebar />
      </nav>

      <main>
        <Routes />
      </main>
    </div>
  );
};

export default Layout;
