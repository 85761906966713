import Button from '@material-ui/core/Button';
import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import useDialogFooterStyles from './useDialogFooterStyles';

const DialogFooter = ({onClose, isSubmitting}) => {
  const classes = useDialogFooterStyles();
  return (
    <DialogActions className={classes.dialogActionButtons}>
      <Button onClick={onClose} variant="contained">
        Cancel
      </Button>
      <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
        {isSubmitting ? 'Submitting..' : 'Submit'}
      </Button>
    </DialogActions>
  );
};
DialogFooter.propTypes = {
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default DialogFooter;
