import React from 'react';
import {Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const ResponseSnackbar = ({open, success, message, onClose}) => {
  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
        <Alert onClose={onClose} severity={success} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
ResponseSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  success: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ResponseSnackbar;
