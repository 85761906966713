import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from '@material-ui/core';
import {useHistory} from 'react-router';
import Button from '@material-ui/core/Button';
import useLoginStyles from './useLoginStyles';
import userManager from '../../utils/userManager';
import setToken from '../../state/actions/setToken';
import {loginRedirectPath} from '../../utils/constants';

const Login = () => {
  const user = useSelector(state => state.oidc.user);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user !== null) {
      const redirectUrl = window.sessionStorage.getItem(loginRedirectPath) || '/';
      window.sessionStorage.removeItem(loginRedirectPath);
      sessionStorage.setItem('token', `Bearer ${user.access_token}`);
      dispatch(setToken(`Bearer ${user.access_token}`));
      sessionStorage.setItem('email', user.profile.name);
      history.push(redirectUrl);
    }
  }, [user]);

  const classes = useLoginStyles();
  const redirectToSSO = () => {
    userManager.signinRedirect();
  };
  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <div className={classes.center}>
          <Button variant="contained" color="primary" onClick={redirectToSSO}>
            Login with Devbridge SSO
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Login;
