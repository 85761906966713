import makeStyles from '@material-ui/core/styles/makeStyles';

export const useHeaderStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'whitesmoke',
    position: 'absolute',
    borderBottom: '3px solid #b4b3b4',
    display: 'flex',
    flexDirection: 'row',
    height: 60,
  },
}));

export const useCrumbsStyles = makeStyles(() => ({
  breadcrumbs: {
    paddingLeft: 1,
    backgroundColor: 'inherit',
    position: 'absolute',
    marginLeft: 40,
    marginTop: 15,
    fontSize: 20,
    color: '#FF3454',
  },

  typography: {
    fontSize: 20,
    color: '#7158a8',
  },
}));
