import {makeStyles} from '@material-ui/core/styles';

const useSettingsStyles = makeStyles(theme => ({
  title: {
    fontSize: 'x-large',
    paddingBottom: 10,
    paddingRight: 20,
  },
  paper: {
    padding: theme.spacing(2),
  },
  field: {
    width: '21rem',
  },
}));

export default useSettingsStyles;
