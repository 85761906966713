import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import useHelpStyles from './useHelpStyles';

const Help = () => {
  const classes = useHelpStyles();

  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <Paper elevation={3} className={classes.root}>
        <Typography variant="h5" className={classes.smallMargin} gutterBottom>
          <b>System overview</b>
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.indent}>
          DevOps Metrics is a system created for “Devbridge” employees to automate their process of
          registering their project deployments and receive various metrics, charts, calculations,
          filtering options based on their input. Navigation consists of:
        </Typography>
        <ul className={classes.margin}>
          <li>
            <b>Projects - </b>displays a project list, which can be filtered by last deployment
            date, project name, squad name. You can create, view, edit, delete existing projects.
          </li>
          <li>
            <b>Metrics - </b>displays a global metrics table that displays each project’s deployment
            frequency, lead time, deployment duration, failure rate and their global average and
            median values. It can be filtered by date, project name and squad name.
          </li>
          <li>
            <b>Progress - </b>displays all projects as well as a progress chart and deployment list.
            Displays a progress chart, which indicates how well projects at “Devbridge” are doing
            overall.
          </li>
          <li>
            <b>Quadrant - </b>displays all projects in a quadrant chart. The lines represent a goal
            that administrators can set for failure rate and deployment frequency metrics.
          </li>
          <li>
            <b>Squads - </b>displays a squad list. You can create, edit and delete existing squads.
          </li>
        </ul>

        <Typography variant="body2" gutterBottom>
          <b>Terms used in the system:</b>
        </Typography>
        <ul className={classes.margin}>
          <li>
            <b>Deployment frequency - </b>how often an application is deployed to production. It is
            measured in deployments/day (deployments per day).
          </li>
          <li>
            <b>Lead time - </b>how much time it takes for us to deliver a finished feature/release
            candidate to production in reality by following the full deployment process. It is
            measured in minutes.
          </li>
          <li>
            <b>Deployment duration - </b>how fast we could deliver a finished and fully tested
            feature/release candidate to production (our potential). It is measured in minutes.
          </li>
          <li>
            <b>Failure rate - </b>how often our production deployments contain escaped defects. The
            quantity of defects does not really matter for this metric; it should only measure if
            the release was successful (no escaped defects) or not. It is measured in deployments
            with defects/total deployments.
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          <b>Projects</b>
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.indent}>
          Project page displays all projects. The table displays project name, deployment count,
          squad name and project’s last deployment date. You can create, view, edit, delete and
          filter existing projects. Filtering allows you to filter by:
        </Typography>
        <ul className={classes.smallMargin}>
          <li>Last deployment date (date from and date to can also work independently)</li>
          <li>Project name</li>
          <li>Squad name</li>
        </ul>
        <i className={classes.margin}>Notes:</i>
        <ol className={classes.margin}>
          <li>
            <i>Each project must be assigned to a squad.</i>
          </li>
          <li>
            <i>No two projects can have identical titles.</i>
          </li>
          <li>
            <i>
              Projects are pre-production by default, unless they contain at least one deployment.
              If all the deployments of a project are deleted, the project’s status is reverted back
              to pre-production.
            </i>
          </li>
          <li>
            <i>
              Projects that are in the pre-production stage are not included in the metric
              calculations.
            </i>
          </li>
        </ol>
        <Typography variant="h6" gutterBottom>
          <b>Project view</b>
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.indent}>
          The project view page can be accessed through the project list by clicking on the view
          icon next to a project. This page displays additional project details; allows you to edit
          and delete the project. This page is also used for creating, editing and deleting existing
          deployments. You can also view the progress chart, which displays the project&apos;s
          metrics: deployment frequency, lead time, deployment duration, failure rate average and
          median values. The axes:
        </Typography>
        <ul>
          <li>X-axis - date (daily, monthly, yearly)</li>
          <li>
            Y-axis - value of the metric of your choice (deployment frequency, lead time, deployment
            duration, failure rate)
          </li>
        </ul>
        <Typography variant="body2" className={classes.smallMargin}>
          The project&apos;s progress chart and it&apos;s deployments can be filtered by date.
        </Typography>
        <i className={classes.margin}>Notes:</i>
        <ol className={classes.margin}>
          <li>
            <i>
              In case of lead time and deployment duration, if there are no deployments created on a
              certain date, then the chart will display the previous day’s/month’s/year’s data
              (Example: if the average deployment duration on the 24th was 180 minutes and no
              deployments were created on the 25th, then the 25th will also display an average of
              180 minutes).
            </i>
          </li>
          <li>
            <i>
              Additionally the chart can only display 30 days (if daily selected), 12 months (if
              monthly selected) and 5 years (if yearly selected) intervals due to the limited space
              in the chart.
            </i>
          </li>
        </ol>

        <Typography variant="h6" gutterBottom>
          <b>Global Metrics</b>
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.indent}>
          The global metrics page displays all the projects’ metrics in the system. The table
          displays each project’s name and corresponding squad’s name as well as its metrics:
          deployment frequency, lead time, deployment duration, failure rate as well as the average
          and median values of these metrics. This table can be filtered by:
        </Typography>
        <ul className={classes.margin}>
          <li>Last deployment date (date from and date to can also work independently)</li>
          <li>Project name</li>
          <li>Squad name</li>
        </ul>

        <Typography variant="h6" gutterBottom>
          <b>Progress Chart</b>
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.indent}>
          The progress chart displays all projects which are already in production (have at least 1
          deployment). This chart is global, meaning it aggregated all the projects’ data and
          displays the average and median values of the following metrics: deployment frequency,
          lead time, deployment duration, failure rate. The axes:
        </Typography>
        <ul>
          <li>X-axis - date (daily, monthly, yearly)</li>
          <li>
            Y-axis - value of the metric of your choice (deployment frequency, lead time, deployment
            duration, failure rate)
          </li>
        </ul>
        <Typography variant="body2" gutterBottom>
          This chart can be filtered by:
        </Typography>
        <ul className={classes.smallMargin}>
          <li>Date (date from and date to can also work independently)</li>
          <li>Project name</li>
          <li>Squad name</li>
        </ul>
        <i className={classes.margin}>Notes:</i>
        <ol className={classes.margin}>
          <li>
            <i>
              In case of lead time and deployment duration, if there are no deployments created on a
              certain date, then the chart will display the previous day’s/month’s/year’s data
              (Example: if the average deployment duration on the 24th was 180 minutes and no
              deployments were created on the 25th, then the 25th will also display an average of
              180 minutes).
            </i>
          </li>
          <li>
            <i>
              Additionally the chart can only display 30 days (if daily selected), 12 months (if
              monthly selected) and 5 years (if yearly selected) intervals due to the limited space
              in the chart.
            </i>
          </li>
        </ol>

        <Typography variant="h6" gutterBottom>
          <b>Quadrant</b>
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.indent}>
          The quadrant displays all projects which are already in production (have at least 1
          deployment) as dots/points. The axes:
        </Typography>
        <ul>
          <li>X-axis - deployment frequency (deployments per day)</li>
          <li>Y-axis - failure rate (%)</li>
        </ul>
        <Typography variant="body2" gutterBottom>
          The lines represent a goal that administrators can set for failure rate and deployment
          frequency metrics. Also you can filter the projects by:
        </Typography>
        <ul className={classes.smallMargin}>
          <li>Deployment date (date from and date to can also work independently)</li>
          <li>Project name</li>
          <li>Squad name</li>
        </ul>
        <Typography variant="body2" className={classes.smallMargin}>
          The quadrant can indicate how well a project or a group of projects are doing by looking
          at which part of the quadrant they are in.
        </Typography>
        <Typography variant="body2" className={classes.margin}>
          <i>
            Note: When filtering by deployment date, it does not only filter the project’s
            deployments but also it starts to use the scope of the dates for the deployment
            frequency calculations. Example: date from 2020-12-09 -&gt; date to 2020-12-17. Means
            the scope is 8 days (instead of first deployment to now). If 2 deployments are found for
            this period, then it will calculate 2/8=0.25 deployment frequency.
          </i>
        </Typography>

        <Typography variant="h6" gutterBottom>
          <b>Squads</b>
        </Typography>
        <Typography variant="body2" className={classes.identWithMargin}>
          The squads page displays all squads. The table displays only the squad name. You can
          create, edit and delete existing squads.
        </Typography>
        <i className={classes.margin}>Notes:</i>
        <ol className={classes.margin}>
          <li>
            <i>
              You will not be able to delete a squad if it already has at least one existing project
              assigned.
            </i>
          </li>
          <li>
            <i>You will not be allowed to create squads with identical names.</i>
          </li>
          <li>
            <i>Users cannot be assigned to a specific squad by the current implementation.</i>
          </li>
        </ol>
      </Paper>
    </Grid>
  );
};

export default Help;
